import { SceneState } from '@store/slices/sceneSlice'
import { RootState } from '@store/store'
import assert from 'assert'
import { ExtendedTuple } from 'types/helper'

export enum EnginePrimitive {
  BOX = 0,
  CYLINDER = 1,
  SPHERE = 2,
  CAPSULE = 3,
  HON = 4,
  DRO = 5,
  TORUS = 6,
  SHOEHORN = 7,
  TRIANGLE = 8,
  CONE = 9,
  STAR = 10,
  POLYGON = 11,
  EGG = 12,
  PIN = 13
}

export enum EngineRepeatType {
  NONE = 0,
  XYZ = 1,
  ANGLE = 2
}

export enum EngineRepeatAngleDirection {
  X = 0,
  Y = 1,
  Z = 2
}

export enum PrimitiveDimensionType {
  EXTRUDE = 0,
  REVOLVE = 1
}

export enum PrimitiveDimensionParam {
  CORNER_1 = 1,
  CORNER_2 = 2,
  REVOLUTION_DISTANCE = 0,
  REVOLUTION_ROTATION = 1
}

export enum PrimitiveSymmetryParam {
  X = 0,
  Y = 1,
  Z = 2
}

export enum PrimitiveSymmetryChecked {
  UNCHECKED = 0,
  CHECKED = 1
}

export enum MaterialType {
  P = 1,
  E = 2,
  PIXEL = 3,
  I = 4
}

export enum MaterialParam {
  ROUGHNESS = 0,
  METALNESS = 1,
  REFLECTIVE = 2,
  E_SPECULAR_INTENSITY = 0,
  E_SPECULAR_SIZE = 1,
  I_STROKE_SIZE = 0,
  I_HIGHLIGHT_INTENSITY = 1,
  I_STROKE_INTENSITY = 2,
  I_COLORVAR_INTENSITY = 3,
  I_SCALE_X = 4,
  I_SCALE_Y = 5,
  I_ANGLE = 6
}

export enum MaterialColorParam {
  COLOR = 0,
  E_COLOR_TOP = 0,
  E_COLOR_LIG = 1,
  E_COLOR_SHA = 2,
  I_COLOR = 0,
  I_EMISSIVE_COLOR = 1
}

export type MaterialProperties = Record<
  keyof typeof MaterialColorParam | keyof typeof MaterialParam,
  number | string
>

export enum PrimitiveModifierProperty {
  REPX = 0,
  REPY = 1,
  REPZ = 2,
  REPA = 0,
  REP_ANGLE_DIRECTION = 1
}

export enum PrimitiveParameter {
  RADIAL_0 = 2,
  RADIAL_1 = 3,
  RADIAL_2 = 4,
  RADIAL_3 = 5,
  STAR_POINTS = 1,
  STAR_ANGLE = 2,
  STAR_CORNERS = 3,
  TRIANGLE_VERTEX = 2,
  HORSESHOE_ANGLE = 0,
  HORSESHOE_RADIUS = 1,
  HORSESHOE_WIDTH = 2,
  HORSESHOE_LENGTH = 3,
  HORSESHOE_THICKNESS = 4,
  HORSESHOE_CORNERS = 5
}

export enum EngineBackground {
  SOLID = 0,
  GRADIENT_RADIAL = 1,
  GRADIENT_LINEAR = 2
}

export enum EngineCamera {
  PERSPECTIVE = 0,
  ISOMETRIC = 1,
  FREE_ORTHOGONAL = 2
}

export function isOrthographic(type: EngineCamera): boolean {
  let ok =
    type === EngineCamera.ISOMETRIC || type === EngineCamera.FREE_ORTHOGONAL
  return ok
}

export function toggleIsometricConstraint(
  cameraType: EngineCamera
): EngineCamera {
  return cameraType === EngineCamera.ISOMETRIC
    ? EngineCamera.FREE_ORTHOGONAL
    : EngineCamera.ISOMETRIC
}

export enum EngineCameraProperty {
  // Focal Length/Size depends on the camera type
  FOCAL_LENGTH_OR_SIZE = 0,
  FOCAL_PLANE = 1,
  APERTURE = 2,
  DISTORTION = 3
}

export enum EngineUndoRedo {
  UNDO = 0,
  REDO = 1
}

export enum EngineBlendType {
  ADD = 0,
  SUBTRACT = 1,
  INTERSECT = 2,
  COLOR = 3,
  REPEL = 4,
  AVOID = 5
}

export enum EngineSelectParentChild {
  UP = -1,
  DOWN = 1
}

export enum EngineSelectSibling {
  LEFT = -1,
  RIGHT = 1
}

export enum EngineSymmetryAxis {
  X = 0
}

export enum EngineLightStatus {
  ENABLED = 0,
  DISABLED = 1
}

export enum EngineMode {
  NORMAL = 1,
  OUTLINE = 2,
  PIXEL = 3,
  ILLUSTRATIVE = 4
}

export enum EngineSelectedSceneNode {
  NONE = 0,
  SHAPE = 1,
  GROUP = 2,
  PROJECT = 3,
  MULTI_SELECTED = 4
}

export type EngineData =
  | {
      status: EngineSelectedSceneNode.NONE
      sceneData: string
    }
  | {
      status: EngineSelectedSceneNode.SHAPE
      sceneData: string | SceneElement
    }
  | {
      status: EngineSelectedSceneNode.GROUP
      sceneData: SceneElementGroup
    }
  | {
      status: EngineSelectedSceneNode.PROJECT
      origin: EngineDataOrigin
      sceneData: Scene
    }
  | {
      status: EngineSelectedSceneNode.MULTI_SELECTED
      sceneData: string
    }

export interface SelectedObjectUI {
  status: number
  editButtonGizmoPosition: {
    centerX: number
    centerY: number
  }
}

export enum EngineCommitChange {
  // Looks weird set these to these values, but the lights uses the old undo/redo api, and
  // the old api treats begin commit as a 1 and an end commit as a 4
  BEGIN_COMMIT = 1,
  END_COMMIT = 4
}

export enum EngineCommitOrigin {
  LOCAL = 0,
  REMOTE = 2
}

export enum EngineDataOrigin {
  LOCAL_INPUT = 0,
  ENGINE_OUTPUT = 1,
  REMOTE_INPUT = 2
}

export type FrameType = number

export interface Scene {
  info: SceneInfo
  background: ExtendedTuple<number, 7>
  camera: [EngineCamera, number, number, number, number] // [cameraType, cameraValue, cameraFocalPlane, cameraAperture, cameraDistortion]
  floor: { enabled: boolean; height: number }
  frame: [boolean, number, FrameType, number, number] // [enabled, opacity, frame type, dimensionX, dimensionY]
  style:
    | { id: EngineMode.NORMAL }
    | {
        id: EngineMode.OUTLINE
        color: [number, number, number]
        outline: number
      }
    | {
        id: EngineMode.PIXEL
        color: [number, number, number]
        pixelsize: number
        filter: number
        outline: boolean
      }
  // | {
  //     id: EngineMode.ILLUSTRATIVE
  //   }

  lighting: {
    occlusion: { distance: number; color: [number, number, number] }
    lights: [{ position: number; altitude: number }]
  }
}

export interface SceneInfo {
  name: string
  size: number
}

export interface SceneElement {
  pos: ExtendedTuple<number, 3>
  rot: ExtendedTuple<number, 3>
  sca: ExtendedTuple<number, 3>
  symmetry: ExtendedTuple<boolean, 3>
  repeat:
    | [EngineRepeatType.NONE]
    | [EngineRepeatType.XYZ, number, number, number, number, number, number]
    | [EngineRepeatType.ANGLE, number, number, number]

  group: SceneGroup
  address: string
  blend: [EngineBlendType, number]
  material:
    | [
        number,
        MaterialType.P,
        number,
        number,
        number,
        number,
        number,
        number,
        number
      ]
    | [
        number,
        MaterialType.E,
        number,
        number,
        number,
        number,
        number,
        number,
        number,
        number,
        number,
        number,
        number,
        number
      ]
    | [
        number,
        MaterialType.PIXEL,
        number,
        number,
        number,
        number,
        number,
        number,
        number,
        number,
        number,
        number,
        number,
        number
      ]
    | [
        number,
        MaterialType.I,
        number,
        number,
        number,
        number,
        number,
        number,
        number,
        number,
        number,
        number,
        number,
        number,
        number
      ]
  prim: ScenePrim
}

export interface SceneElementGroup
  extends Omit<SceneElement, 'material' | 'prim'> {}

export interface SceneGroup {
  expanded: boolean
}

export enum ScenePrimType {
  SOFT_BOX = 0,
  STAR = 1,
  HORSESHOE = 2,
  TRIANGLE = 3,
  EGG = 4,
  TEXT = 5,
  NUM = 6
}

export type SceneParamGeneric<
  Type extends ScenePrimType,
  ParamLength extends number,
  DimensionType extends PrimitiveDimensionType
> = {
  type: Type
  params: ExtendedTuple<number, ParamLength>
  common: ExtendedTuple<number, 3>
  dimension: ExtendedTuple<number, 4>
  thickness: ValueAndRange
  round: ValueAndRange
} & (DimensionType extends PrimitiveDimensionType.EXTRUDE
  ? {
      corner1: ValueAndRange
      corner2: ValueAndRange
    }
  : {}) &
  (Type extends ScenePrimType.STAR ? { cornerStar: ValueAndRange } : {})

export type ScenePrim = ScenePrimByDimensionType<PrimitiveDimensionType>

export type ScenePrimByDimensionType<
  DimensionType extends PrimitiveDimensionType
> =
  | (SceneParamGeneric<ScenePrimType.SOFT_BOX, 4, DimensionType> & {
      settings: { scaleCorners: boolean }
    })
  | SceneParamGeneric<ScenePrimType.STAR, 3, DimensionType>
  | SceneParamGeneric<ScenePrimType.HORSESHOE, 6, DimensionType>
  | SceneParamGeneric<ScenePrimType.TRIANGLE, 1, DimensionType>
  | SceneParamGeneric<ScenePrimType.EGG, 4, DimensionType>
  | SceneParamGeneric<ScenePrimType.NUM, 5, DimensionType>

export enum GroupExpanded {
  NORMAL = 0,
  EXPANDED = 1
}

export interface LocalUserLocation {
  location: ExtendedTuple<number, 8>
}

export interface RemoteUserStatus {
  remote_user_uuid: string
  status: string
  color?: ExtendedTuple<number, 3>
}

export interface RemoteUserLocation {
  remote_user_uuid: string
  location: ExtendedTuple<number, 8>
}

export interface SceneNavigatorData {
  info: {
    elements: SceneNavigatorElement[]
    name: string
    depth: number
  }
}

export enum ElementIconId {
  GROUP = 0,
  CUBE = 1,
  CYLINDER = 2,
  SPHERE = 3,
  CAPSULE = 4,
  CONE = 5,
  TORUS = 6,
  U = 7,
  DROP = 8,
  TRIANGLE = 9,
  CONE_DUPE = 10,
  STAR = 11,
  POLYGON = 12,
  EGG = 13,
  PIN = 14
}

export interface SceneNavigatorElement {
  blend: number
  color: number
  combine: EngineBlendType
  iconID: ElementIconId
  locked: boolean
  selected: boolean
  uuid: string
  visible: boolean
  name: string
}

export type SceneNavigatorEventPayload =
  | {
      data: SceneNavigatorData
      status: 'AT_LEAST_ONE_ELEMENT_SELECTED'
    }
  | {
      status: 'NO_ELEMENT_SELECTED'
    }

export interface Cartesian {
  x: number
  y: number
  z: number
}

export class CCartesian implements Cartesian {
  x = 0
  y = 0
  z = 0

  constructor()
  constructor(x: number, y: number, z: number)
  constructor(...params: any[]) {
    if (params.length == 0) {
      // do nothing already initialized in declaration
    } else if (params.length == 3) {
      this.x = params[0]
      this.y = params[1]
      this.z = params[3]
    } else {
      assert('invalid args')
    }
  }
}
export interface Spherical {
  r: number
  phi: number
  theta: number
}

export class CSpherical implements Spherical {
  r = 0
  phi = 0
  theta = 0
}
export interface CameraPosition {
  target: Cartesian
  view: Spherical
}

export class CCameraPosition implements CameraPosition {
  target = new CCartesian()
  view = new CSpherical()
}

export interface CameraFromTo {
  from: Cartesian
  to: Cartesian
}

export enum EngineStackReorderingMode {
  BEFORE = 0,
  AFTER = 1
}

export interface EngineStackReorderingOpts {
  srcIndices: Array<number>
  destIndex: number
  mode: EngineStackReorderingMode
}

export type EngineCaptureImagePayload = {
  objectURL: string
  blobArray?: Uint8Array
  depthObjectURL?: string
  depthBlobArray?: Uint8Array
  colorObjectURL?: string
  colorBlobArray?: Uint8Array
  format: 'jpg' | 'png' | 'svg' | 'screenshot' | 'reference'
}

export type EngineExportCapturePayload =
  | {
      format: 'svg'
      splitFaceEnabled: boolean
    }
  | {
      format: 'bmp'
      height: number
    }
  | {
      format: 'png'
      height: number
      transparentBackground: boolean
      compressionLevel?: number // between 0 and 9
    }
  | {
      format: 'jpg'
      height: number
      quality?: number // between 0 and 100
    }
  | {
      format: 'screenshot'
    }
  | {
      format: 'reference'
    }

export type FrameCallbackData = {
  info: {
    enabled: boolean
    size: [number, number]
    topleft: [number, number]
  }
}

export type FrameEventPayload = {
  enabled: boolean
  size: { height: number; width: number }
  position: { top: number; left: number }
}

export enum DeviceStatusEventPayload {
  SLOW_INTEL_GPU = 'SLOW_INTEL_GPU'
}

export type ModelLoadedStatusEventPayload = {
  status?: number
  sceneData?: string
}

export interface ValueAndRange {
  value: number
  min: number
  max: number
}

export type Vec2d = { x: number; y: number }
export type IVec2 = Vec2d
export type Vec3d = { x: number; y: number; z: number }
export type IVec3 = Vec3d

export type PropertyPanelId = string

export type PropertyPanelVariant =
  | 'int'
  | 'float'
  | 'vec2d'
  | 'vec3d'
  | 'bool'
  | 'EnumBase'
  | 'string'
  | 'ivec2'
  | 'ivec3'

export const IntGuard = (val: any): boolean => {
  const numVal = Number(val)
  return (
    typeof numVal === 'number' &&
    !isNaN(numVal) &&
    numVal - Math.floor(numVal) === 0
  )
}

export const FloatGuard = (val: any): boolean => {
  const numVal = Number(val)
  return typeof numVal === 'number' && !isNaN(numVal)
}

export const Vec2dGuard = (val: any): boolean => {
  return VecGuard(val, 2)
}

export const Vec3dGuard = (val: any): boolean => {
  return VecGuard(val, 3)
}

const VecGuard = (val: any, vecLength: number) => {
  const keys = Object.keys(val)

  return (
    Array.isArray(keys) &&
    keys.length === vecLength &&
    keys.every(key => typeof val[key] === 'number')
  )
}

export const BoolGuard = (val: any): boolean => {
  return typeof val === 'boolean'
}

export const EnumBaseGuard = IntGuard

export const StringGuard = (val: any): boolean => {
  return typeof val === 'string'
}

export const IVec2Guard = (val: any): boolean => {
  return IntGuard(val.x) && IntGuard(val.y) && Vec2dGuard(val)
}

export const IVec3Guard = (val: any): boolean => {
  return (
    IntGuard(val.x) && IntGuard(val.y) && IntGuard(val.z) && Vec3dGuard(val)
  )
}

export type PropertyPanelSetter = (
  path: string,
  value: any,
  panelId?: string
) => PropertyPanelSetStatus

export enum PropertyPanelSetStatus {
  WrongPath,
  IncorrectType,
  ValueAlreadySet,
  IncorrectValue,
  Success
}

const propertyPaths = [
  // Primitive paths
  'shapeMaterial.pbrColor',
  'shapeMaterial.pbrRoughness',
  'shapeMaterial.pbrMetalness',
  'shapeMaterial.pbrIsReflective',
  'treeNode.standard.position',
  'treeNode.standard.rotation',
  'treeNode.standard.name',
  'treeNode.standard.blendMode',
  'treeNode.standard.blendShape',
  'treeNode.standard.repeatMode',
  'treeNode.standard.repeatX',
  'treeNode.standard.repeatY',
  'treeNode.standard.repeatZ',
  'treeNode.standard.distanceX',
  'treeNode.standard.distanceY',
  'treeNode.standard.distanceZ',
  'treeNode.standard.circularCopyAxis',
  'treeNode.standard.circularCopyCount',
  'treeNode.standard.circularCopyDistance',
  'treeNode.standard.alongX',
  'treeNode.standard.alongY',
  'treeNode.standard.alongZ',
  'treeNode.standard.onion',
  'treeNode.standard.round',
  'treeNode.standard.thickness',
  'treeNode.dimension.mode',
  'treeNode.dimension.extrudeAmount',
  'treeNode.dimension.topFillet',
  'treeNode.dimension.bottomFillet',
  'treeNode.dimension.distance',
  'treeNode.dimension.rotation',
  'treeNode.size.width',
  'treeNode.size.height',
  'treeNode.cornerRounding0',
  'treeNode.cornerRounding1',
  'treeNode.cornerRounding2',
  'treeNode.cornerRounding3',
  'treeNode.angle',
  'treeNode.radius',
  'treeNode.width',
  'treeNode.length',
  'treeNode.thickness',
  'treeNode.corners',
  'treeNode.dartOffset',
  'treeNode.sideCount',
  'treeNode.roundingRadius',
  'treeNode.radiusA',
  'treeNode.radiusB',
  'treeNode.font',
  'treeNode.text',
  'treeNode.weight',
  'treeNode.size',
  'treeNode.letterSpacing',
  'treeNode.lineHeight',
  'shapeMaterial.exprColorTop',
  'shapeMaterial.exprColorLight',
  'shapeMaterial.exprColorDark',
  'shapeMaterial.exprSpecularSize',
  'shapeMaterial.exprSpecularIntensity',
  'shapeMaterial.illustrativeColor',
  'shapeMaterial.illustrativeEmissiveColor',
  'shapeMaterial.illustrativeStrokeSize',
  'shapeMaterial.illustrativeSpecularIntensity',
  'shapeMaterial.illustrativeNormalIntensity',
  'shapeMaterial.illustrativeColorVariation',
  'shapeMaterial.illustrativeScaleX',
  'shapeMaterial.illustrativeScaleY',
  'shapeMaterial.illustrativeAngle',

  // Global paths
  'cameraLens.distortionAmount',
  'cameraLens.dofAperture',
  'cameraLens.dofFocalPlane',
  'cameraLens.perspFocalLength',
  'cameraLens.orthoSize',
  'background.colorA',
  'background.colorB',
  'background.gradientMode',
  'frame.frameType',
  'frame.size',
  'frame.opacity',
  'frame.enabled',
  'floor.enabled',
  'floor.height',
  'cameraLens.type',
  'style.style',
  'style.exprOutline',
  'style.exprColor',
  'style.pixelArtPixelCount',
  'style.pixelArtEnabled',
  'style.pixelArtColor',
  'style.pixelArtOutlineThickness',
  'style.illusLightTexture',
  'style.illusShadowTexture',
  'style.illusGlobalStrokeSize',
  'style.illusFilterStrength',
  'style.illusEdgeStrength',
  'style.illusLightIntensity',
  'style.illusAOIntensity',
  'style.illusOutlineEnabled',
  'style.illusOutlineTolerance',
  'style.illusOutlineColor',
  'style.illusHighlightColor',
  'style.illusShadowColor',
  'style.illusSkyColor',
  'style.illusBounceColor'
] as const

export type PropertyPanelPath = (typeof propertyPaths)[number]

export type PropertyPanelPathVariantDictType = Record<
  PropertyPanelPath,
  PropertyPanelVariant
>

export const propertyPanelPathVariantDict: PropertyPanelPathVariantDictType = {
  // Primitive paths
  'shapeMaterial.pbrColor': 'ivec3',
  'shapeMaterial.pbrIsReflective': 'bool',
  'shapeMaterial.pbrMetalness': 'float',
  'shapeMaterial.pbrRoughness': 'float',
  'treeNode.cornerRounding0': 'float',
  'treeNode.cornerRounding1': 'float',
  'treeNode.cornerRounding2': 'float',
  'treeNode.cornerRounding3': 'float',
  'treeNode.dimension.bottomFillet': 'float',
  'treeNode.dimension.distance': 'float',
  'treeNode.dimension.extrudeAmount': 'float',
  'treeNode.dimension.mode': 'EnumBase',
  'treeNode.dimension.rotation': 'float',
  'treeNode.dimension.topFillet': 'float',
  'treeNode.size.height': 'float',
  'treeNode.size.width': 'float',
  'treeNode.standard.alongX': 'bool',
  'treeNode.standard.alongY': 'bool',
  'treeNode.standard.alongZ': 'bool',
  'treeNode.standard.blendMode': 'EnumBase',
  'treeNode.standard.blendShape': 'float',
  'treeNode.standard.circularCopyAxis': 'EnumBase',
  'treeNode.standard.circularCopyCount': 'int',
  'treeNode.standard.circularCopyDistance': 'float',
  'treeNode.standard.onion': 'float',
  'treeNode.standard.round': 'float',
  'treeNode.standard.thickness': 'float',
  'treeNode.standard.distanceX': 'float',
  'treeNode.standard.distanceY': 'float',
  'treeNode.standard.distanceZ': 'float',
  'treeNode.standard.name': 'string',
  'treeNode.standard.position': 'vec3d',
  'treeNode.standard.repeatMode': 'EnumBase',
  'treeNode.standard.repeatX': 'int',
  'treeNode.standard.repeatY': 'int',
  'treeNode.standard.repeatZ': 'int',
  'treeNode.standard.rotation': 'vec3d',
  'treeNode.angle': 'float',
  'treeNode.radius': 'float',
  'treeNode.width': 'float',
  'treeNode.length': 'float',
  'treeNode.thickness': 'float',
  'treeNode.corners': 'float',
  'treeNode.dartOffset': 'float',
  'treeNode.sideCount': 'int',
  'treeNode.roundingRadius': 'float',
  'treeNode.radiusA': 'float',
  'treeNode.radiusB': 'float',
  'treeNode.font': 'string',
  'treeNode.text': 'string',
  'treeNode.weight': 'string',
  'treeNode.size': 'float',
  'treeNode.letterSpacing': 'float',
  'treeNode.lineHeight': 'float',
  'shapeMaterial.exprColorTop': 'ivec3',
  'shapeMaterial.exprColorLight': 'ivec3',
  'shapeMaterial.exprColorDark': 'ivec3',
  'shapeMaterial.exprSpecularSize': 'float',
  'shapeMaterial.exprSpecularIntensity': 'float',
  'shapeMaterial.illustrativeColor': 'ivec3',
  'shapeMaterial.illustrativeEmissiveColor': 'ivec3',
  'shapeMaterial.illustrativeStrokeSize': 'float',
  'shapeMaterial.illustrativeSpecularIntensity': 'float',
  'shapeMaterial.illustrativeNormalIntensity': 'float',
  'shapeMaterial.illustrativeColorVariation': 'float',
  'shapeMaterial.illustrativeScaleX': 'float',
  'shapeMaterial.illustrativeScaleY': 'float',
  'shapeMaterial.illustrativeAngle': 'float',

  // Global paths
  'cameraLens.distortionAmount': 'float',
  'cameraLens.dofAperture': 'float',
  'cameraLens.dofFocalPlane': 'float',
  'cameraLens.perspFocalLength': 'float',
  'cameraLens.orthoSize': 'float',
  'background.colorA': 'ivec3',
  'background.colorB': 'ivec3',
  'background.gradientMode': 'EnumBase',
  'frame.frameType': 'EnumBase',
  'frame.size': 'ivec2',
  'frame.opacity': 'float',
  'frame.enabled': 'bool',
  'floor.enabled': 'bool',
  'floor.height': 'float',
  'cameraLens.type': 'EnumBase',
  'style.style': 'EnumBase',
  'style.exprOutline': 'int',
  'style.exprColor': 'ivec3',
  'style.pixelArtPixelCount': 'int',
  'style.pixelArtEnabled': 'bool',
  'style.pixelArtColor': 'ivec3',
  'style.pixelArtOutlineThickness': 'int',
  'style.illusLightTexture': 'int',
  'style.illusShadowTexture': 'int',
  'style.illusGlobalStrokeSize': 'int',
  'style.illusFilterStrength': 'int',
  'style.illusEdgeStrength': 'int',
  'style.illusLightIntensity': 'int',
  'style.illusAOIntensity': 'int',
  'style.illusOutlineEnabled': 'bool',
  'style.illusOutlineTolerance': 'int',
  'style.illusOutlineColor': 'ivec3',
  'style.illusHighlightColor': 'ivec3',
  'style.illusShadowColor': 'ivec3',
  'style.illusSkyColor': 'ivec3',
  'style.illusBounceColor': 'ivec3'
}

export type PropertyHasChangedData = {
  path: PropertyPanelPath
  panelId: string
  value: any
}

type PropertyListType = {
  Label:
    | 'Frame'
    | 'Background'
    | 'Floor'
    | 'Camera lens'
    | 'Shape material'
    | 'Primitive parameters'
  Properties: {
    path: PropertyPanelPath
    type: PropertyPanelVariant
    visibility: boolean
    value: any
  }[]
}

export type PropertyListHasChangedData = {
  UI: {
    Groups: PropertyListType[]
  }
}

export type PropertySoftBoundsHaveChangedData = {
  path: PropertyPanelPath
  min: number
  max: number
}

export type SceneContentData = {
  primitiveType: RootState['scene']['primitiveType']
  selectedSceneNode: RootState['scene']['selectedSceneNode']
  styleMode: EngineMode
}

export type SnapshotListHasChangedData = { snapshots: Snapshot[] }
export type SnapshotRenamedData = { snapshot: [Snapshot] }

export const sceneKeyToPathDict: Partial<
  Record<keyof SceneState, PropertyPanelPath>
> = {
  backgroundColorA: 'background.colorA',
  backgroundColorB: 'background.colorB',
  backgroundType: 'background.gradientMode',
  cameraDistortion: 'cameraLens.distortionAmount',
  cameraAperture: 'cameraLens.dofAperture',
  cameraFocalPlane: 'cameraLens.dofFocalPlane',
  cameraSize: 'cameraLens.orthoSize',
  cameraFocalLength: 'cameraLens.perspFocalLength',
  floorEnabled: 'floor.enabled',
  floorHeight: 'floor.height',
  frameType: 'frame.frameType',
  frameSize: 'frame.size',
  frameEnabled: 'frame.enabled',
  frameOpacity: 'frame.opacity',
  materialColor: 'shapeMaterial.pbrColor',
  materialReflective: 'shapeMaterial.pbrIsReflective',
  materialMetalness: 'shapeMaterial.pbrMetalness',
  materialRoughness: 'shapeMaterial.pbrRoughness',
  primitiveHorseshoeAngle: 'treeNode.angle',
  primitiveRadial0: 'treeNode.cornerRounding0',
  primitiveRadial1: 'treeNode.cornerRounding1',
  primitiveRadial2: 'treeNode.cornerRounding2',
  primitiveRadial3: 'treeNode.cornerRounding3',
  primitiveHorseshoeCorner: 'treeNode.corners',
  primitiveTriangleVertex: 'treeNode.dartOffset',
  primitiveCornerOne: 'treeNode.dimension.bottomFillet',
  primitiveDistance: 'treeNode.dimension.distance',
  transformScaleX: 'treeNode.size.width',
  transformScaleY: 'treeNode.dimension.extrudeAmount',
  transformScaleZ: 'treeNode.size.height',
  primitiveDimensionType: 'treeNode.dimension.mode',
  primitiveRotation: 'treeNode.dimension.rotation',
  primitiveCornerTwo: 'treeNode.dimension.topFillet',
  primitiveHorseshoeLength: 'treeNode.length',
  primitiveHorseshoeRadius: 'treeNode.radius',
  primitiveStarCorners: 'treeNode.roundingRadius',
  primitiveStarPoints: 'treeNode.sideCount',
  symmetryX: 'treeNode.standard.alongX',
  symmetryY: 'treeNode.standard.alongY',
  symmetryZ: 'treeNode.standard.alongZ',
  blendType: 'treeNode.standard.blendMode',
  blendAmount: 'treeNode.standard.blendShape',
  repeatAngleDirection: 'treeNode.standard.circularCopyAxis',
  repeatAngle: 'treeNode.standard.circularCopyCount',
  repeatDistance: 'treeNode.standard.circularCopyDistance',
  repeatDistanceX: 'treeNode.standard.distanceX',
  repeatDistanceY: 'treeNode.standard.distanceY',
  repeatDistanceZ: 'treeNode.standard.distanceZ',
  elementName: 'treeNode.standard.name',
  primitiveShell: 'treeNode.standard.onion',
  transformPositionX: 'treeNode.standard.position',
  transformPositionY: 'treeNode.standard.position',
  transformPositionZ: 'treeNode.standard.position',
  repeatType: 'treeNode.standard.repeatMode',
  repeatX: 'treeNode.standard.repeatX',
  repeatY: 'treeNode.standard.repeatY',
  repeatZ: 'treeNode.standard.repeatZ',
  transformRotationX: 'treeNode.standard.rotation',
  transformRotationY: 'treeNode.standard.rotation',
  transformRotationZ: 'treeNode.standard.rotation',
  primitiveRound: 'treeNode.standard.round',
  primitiveHole: 'treeNode.standard.thickness',
  primitiveHorseshoeThickness: 'treeNode.thickness',
  primitiveHorseshoeWidth: 'treeNode.width',
  cameraType: 'cameraLens.type',
  primitiveStarAngle: 'treeNode.angle',
  materialEColorTop: 'shapeMaterial.exprColorTop',
  materialEColorLig: 'shapeMaterial.exprColorLight',
  materialEColorSha: 'shapeMaterial.exprColorDark',
  materialESpecularSize: 'shapeMaterial.exprSpecularSize',
  materialESpecularIntensity: 'shapeMaterial.exprSpecularIntensity',
  materialIColor: 'shapeMaterial.illustrativeColor',
  materialIEmissiveColor: 'shapeMaterial.illustrativeEmissiveColor',
  materialIStrokeSize: 'shapeMaterial.illustrativeStrokeSize',
  materialIHighlightIntensity: 'shapeMaterial.illustrativeSpecularIntensity',
  materialIStrokeIntensity: 'shapeMaterial.illustrativeNormalIntensity',
  materialIColorVarIntensity: 'shapeMaterial.illustrativeColorVariation',
  materialIScaleX: 'shapeMaterial.illustrativeScaleX',
  materialIScaleY: 'shapeMaterial.illustrativeScaleY',
  materialIAngle: 'shapeMaterial.illustrativeAngle'
}

export const pathToSceneKeyDict: Partial<
  Record<PropertyPanelPath, keyof SceneState | (keyof SceneState)[]>
> = {
  'background.colorA': 'backgroundColorA',
  'background.colorB': 'backgroundColorB',
  'background.gradientMode': 'backgroundType',
  'cameraLens.distortionAmount': 'cameraDistortion',
  'cameraLens.dofAperture': 'cameraAperture',
  'cameraLens.dofFocalPlane': 'cameraFocalPlane',
  'cameraLens.orthoSize': 'cameraSize',
  'cameraLens.perspFocalLength': 'cameraFocalLength',
  'floor.enabled': 'floorEnabled',
  'floor.height': 'floorHeight',
  'frame.frameType': 'frameType',
  'frame.size': 'frameSize',
  'frame.enabled': 'frameEnabled',
  'frame.opacity': 'frameOpacity',
  'shapeMaterial.pbrColor': 'materialColor',
  'shapeMaterial.pbrIsReflective': 'materialReflective',
  'shapeMaterial.pbrMetalness': 'materialMetalness',
  'shapeMaterial.pbrRoughness': 'materialRoughness',
  'treeNode.angle': 'primitiveStarAngle',
  'treeNode.cornerRounding0': 'primitiveRadial0',
  'treeNode.cornerRounding1': 'primitiveRadial1',
  'treeNode.cornerRounding2': 'primitiveRadial2',
  'treeNode.cornerRounding3': 'primitiveRadial3',
  'treeNode.corners': 'primitiveHorseshoeCorner',
  'treeNode.dartOffset': 'primitiveTriangleVertex',
  'treeNode.dimension.bottomFillet': 'primitiveCornerOne',
  'treeNode.dimension.distance': 'primitiveDistance',
  'treeNode.size.width': 'transformScaleX',
  'treeNode.dimension.extrudeAmount': 'transformScaleY',
  'treeNode.size.height': 'transformScaleZ',
  'treeNode.dimension.mode': 'primitiveDimensionType',
  'treeNode.dimension.rotation': 'primitiveRotation',
  'treeNode.dimension.topFillet': 'primitiveCornerTwo',
  'treeNode.length': 'primitiveHorseshoeLength',
  'treeNode.radius': 'primitiveHorseshoeRadius',
  'treeNode.roundingRadius': 'primitiveStarCorners',
  'treeNode.sideCount': 'primitiveStarPoints',
  'treeNode.standard.alongX': 'symmetryX',
  'treeNode.standard.alongY': 'symmetryY',
  'treeNode.standard.alongZ': 'symmetryZ',
  'treeNode.standard.blendMode': 'blendType',
  'treeNode.standard.blendShape': 'blendAmount',
  'treeNode.standard.circularCopyAxis': 'repeatAngleDirection',
  'treeNode.standard.circularCopyCount': 'repeatAngle',
  'treeNode.standard.circularCopyDistance': 'repeatDistance',
  'treeNode.standard.distanceX': 'repeatDistanceX',
  'treeNode.standard.distanceY': 'repeatDistanceY',
  'treeNode.standard.distanceZ': 'repeatDistanceZ',
  'treeNode.standard.name': 'elementName',
  'treeNode.standard.onion': 'primitiveShell',
  'treeNode.standard.position': [
    'transformPositionX',
    'transformPositionY',
    'transformPositionZ'
  ],
  'treeNode.standard.repeatMode': 'repeatType',
  'treeNode.standard.repeatX': 'repeatX',
  'treeNode.standard.repeatY': 'repeatY',
  'treeNode.standard.repeatZ': 'repeatZ',
  'treeNode.standard.rotation': [
    'transformRotationX',
    'transformRotationY',
    'transformRotationZ'
  ],
  'treeNode.standard.round': 'primitiveRound',
  'treeNode.standard.thickness': 'primitiveHole',
  'treeNode.thickness': 'primitiveHorseshoeThickness',
  'treeNode.width': 'primitiveHorseshoeWidth',
  'cameraLens.type': 'cameraType',
  'shapeMaterial.exprColorLight': 'materialEColorLig',
  'shapeMaterial.exprColorTop': 'materialEColorTop',
  'shapeMaterial.exprColorDark': 'materialEColorSha',
  'shapeMaterial.exprSpecularSize': 'materialESpecularSize',
  'shapeMaterial.exprSpecularIntensity': 'materialESpecularIntensity',
  'shapeMaterial.illustrativeColor': 'materialIColor',
  'shapeMaterial.illustrativeEmissiveColor': 'materialIEmissiveColor',
  'shapeMaterial.illustrativeStrokeSize': 'materialIStrokeSize',
  'shapeMaterial.illustrativeSpecularIntensity': 'materialIHighlightIntensity',
  'shapeMaterial.illustrativeNormalIntensity': 'materialIStrokeIntensity',
  'shapeMaterial.illustrativeColorVariation': 'materialIColorVarIntensity',
  'shapeMaterial.illustrativeScaleX': 'materialIScaleX',
  'shapeMaterial.illustrativeScaleY': 'materialIScaleY',
  'shapeMaterial.illustrativeAngle': 'materialIAngle'
}

export type PropertyList = Record<string, string>

export type Snapshot = { index: number; name: string }

export const fontWeights = [
  // Not styled
  'Extra Light',
  'Light',
  'Regular',
  'Medium',
  'Semibold',
  'Bold',
  'Black',

  // Italicized
  'Extra Light Italic',
  'Light Italic',
  'Italic',
  'Medium Italic',
  'Semibold Italic',
  'Bold Italic',
  'Black Italic',

  // Oblique
  'Bold Oblique'
] as const

export type FontWeight = (typeof fontWeights)[number]

export const fontWeightMap: Record<FontWeight, string> = {
  // Not styled
  'Extra Light': 'ExtraLight',
  Light: 'Light',
  Regular: 'Regular',
  Medium: 'Medium',
  Semibold: 'Semibold',
  Bold: 'Bold',
  Black: 'Black',

  // Italicized
  'Extra Light Italic': 'ExtraLightIt',
  'Light Italic': 'LightIt',
  Italic: 'It',
  'Medium Italic': 'MediumIt',
  'Semibold Italic': 'SemiboldIt',
  'Bold Italic': 'BoldIt',
  'Black Italic': 'BlackIt',

  // Oblique
  'Bold Oblique': 'BoldOblique'
}

export const fonts = [
  'CourierStd',
  'BrushScriptStd',
  'CooperBlackStd',
  'SourceSans3',
  'SourceSerif4'
] as const

export type Font = (typeof fonts)[number]

export const fontMap: Record<Font, FontWeight[]> = {
  CourierStd: ['Regular', 'Bold', 'Bold Oblique'],
  BrushScriptStd: ['Regular'],
  CooperBlackStd: ['Regular', 'Italic'],
  SourceSans3: [
    'Regular',
    'Extra Light',
    'Light',
    'Medium',
    'Semibold',
    'Bold',
    'Black',

    'Extra Light Italic',
    'Light Italic',
    'Italic',
    'Medium Italic',
    'Semibold Italic',
    'Bold Italic',
    'Black Italic'
  ],
  SourceSerif4: [
    'Regular',
    'Extra Light',
    'Light',
    'Semibold',
    'Bold',
    'Black',

    'Extra Light Italic',
    'Light Italic',
    'Italic',
    'Semibold Italic',
    'Bold Italic',
    'Black Italic'
  ]
}

export type TextPrimitiveProperties = {
  font: Font
  text: string
  size: number
  weight: FontWeight
  letterSpacing: number
  lineHeight: number
}
