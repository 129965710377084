import React, { FC, useMemo } from 'react'
import PanelAccordion from '@components/panel/PanelAccordion'
import { useProjectState, useProjectActions } from '@hooks/useProject'
import {
  FieldLabel,
  Icon,
  MenuItem,
  OverlayTrigger,
  Picker,
  Textfield,
  Tooltip
} from 'ui'
import LineHeightIcon from '/public/s2_icon_line_height.svg'
import TextSizeIcon from '/public/s2_icon_text_size.svg'
import TextSpacingIcon from '/public/s2_icon_text_tracking.svg'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import { FontSizeField } from './FontSizeField'
import { TextSpacing } from './TextSpacing'
import { FontPicker } from './FontPicker'
import { fontMap } from '@services/engine/types'
import { Section } from './ObjectProperties'

const lineHeightPercentages: number[] = [
  -100, -75, -50, -25, -10, 0, 5, 10, 25, 50, 75, 100, 200
]

const textSpacingPercentages: number[] = [
  -100, -75, -50, -25, -10, 0, 5, 10, 25, 50, 75, 100, 200
]

const Text: FC = () => {
  const threeDTextCollapsed = useProjectState('threeDTextCollapsed')
  const size = useProjectState('sizePreference')

  const textPrimitiveProperties = useSceneState('textPrimitiveProperties')
  const { setPanelAccordionItem } = useProjectActions()
  const { setPropertyState } = useSceneActions()

  const fontWeights = useMemo(
    () => fontMap[textPrimitiveProperties.font],
    [textPrimitiveProperties.font]
  )

  return (
    <PanelAccordion
      id={Section.text}
      label="Text"
      collapsed={threeDTextCollapsed}
      onChange={collapsed =>
        setPanelAccordionItem({ key: 'threeDTextCollapsed', collapsed })
      }>
      <div>
        <FieldLabel for="three-d-text-input">Text input</FieldLabel>
        <Textfield
          style={{ width: '100%' }}
          id="three-d-text-input"
          placeholder="Your text here"
          value={textPrimitiveProperties.text}
          // multiLine
          size={size}
          onInput={e => {
            // Add in an end of line character where line breaks are
            const text = e.target.value.split('\n').join('\n')

            setPropertyState({
              key: 'textPrimitiveProperties',
              value: {
                ...textPrimitiveProperties,
                text
              }
            })
          }}
        />
      </div>

      <FontPicker style={{ minWidth: '100%', width: '100%', marginTop: 24 }} />

      <div className="flex align-center justify-between">
        <Picker
          aria-label="text-style"
          size={size}
          style={{ minWidth: '50%', width: '50%' }}
          value={textPrimitiveProperties.weight}
          onchange={e => {
            document.getElementById('canvas')?.focus()
            setPropertyState({
              key: 'textPrimitiveProperties',
              value: {
                ...textPrimitiveProperties,
                weight: e.target.value
              }
            })
          }}
          label={textPrimitiveProperties.weight}>
          {Array.isArray(fontWeights) &&
            fontWeights.map(weight => (
              <MenuItem key={weight} value={weight}>
                {weight}
              </MenuItem>
            ))}
        </Picker>

        <OverlayTrigger placement="top" offset={0}>
          <div className="flex align-center gap-xs" slot="trigger">
            <Icon size={size}>
              <TextSizeIcon />
            </Icon>
            <FontSizeField />
          </div>
          <Tooltip slot="hover-content">Text size</Tooltip>
        </OverlayTrigger>
      </div>

      <div className="flex align-center justify-between">
        <OverlayTrigger placement="top" offset={0}>
          <div className="flex align-center gap-xs" slot="trigger">
            <Icon size={size}>
              <LineHeightIcon />
            </Icon>
            <TextSpacing
              spacingType="text-line-height"
              percentanges={lineHeightPercentages}
            />
          </div>

          <Tooltip slot="hover-content">Text line-height</Tooltip>
        </OverlayTrigger>

        <OverlayTrigger placement="top" offset={0}>
          <div className="flex align-center gap-xs" slot="trigger">
            <Icon size={size}>
              <TextSpacingIcon />
            </Icon>
            <TextSpacing
              spacingType="text-spacing"
              percentanges={textSpacingPercentages}
            />
          </div>
          <Tooltip slot="hover-content">Text spacing</Tooltip>
        </OverlayTrigger>
      </div>
    </PanelAccordion>
  )
}

export default Text
