import React, { ChangeEvent, Fragment } from 'react'
import {
  ActionButton,
  ActionGroup,
  Icon,
  Overlay,
  OverlayCloseEvent,
  OverlayTrigger,
  Popover,
  Slider,
  Switch
} from 'ui'
import { useFlags } from 'launchdarkly-react-client-sdk'
import styles from '@styles/components/FireflyPanel.module.scss'
import Panel from '@components/panel/Panel'
import PanelHeader from '@components/panel/PanelHeader'
import PanelSectionHeader from '@components/panel/PanelSectionHeader'
import PanelContent from '@components/panel/PanelContent'

import BrushIcon from '/public/s2_icon_brush.svg'
import CameraIcon from '/public/s2_icon_camera.svg'
import AddIcon from '/public/s2_icon_add.svg'
import UploadCloudIcon from '/public/s2_icon_upload_cloud_icon.svg'
import BinocularsIcon from '/public/s2_icon_binoculars.svg'
import CloseIcon from '/public/s2_icon_close.svg'
import ImagePlaceholderIcon from '/public/s2_icon_ai_gen_reference_image.svg'
import Substance3DIcon from '/public/substance_3d.svg'
import Substance3DAssetsIcon from '/public/substance_3d_assets.svg'
import FireflyThumbnail from './FireflyThumbnail'
import cn from 'classnames'
import { useFirefly } from '@hooks/useFirefly'
import SelectionCardItem from '@components/selectionCardItem/SelectionCardItem'
import {
  ContentClass,
  PresetCategory,
  StyleImageReference
} from '@store/slices/fireflySlice'
import StylePresets from './stylesPresets.json'
import { useStyleReferences } from './useStyleReferences'
import { useSubstance3DMaterials } from './useSubstance3DMaterials'
import PanelAccordion from '@components/panel/PanelAccordion'
import {
  useProjectState,
  useProjectActions,
  useMaxMediumSizePreference
} from '@hooks/useProject'

const PresetCategories = (
  Object.keys(PresetCategory) as Array<keyof typeof PresetCategory>
).map(key => PresetCategory[key])

const PopularPresets = [
  {
    category: PresetCategory.EFFECTS,
    id: 'bokeh'
  },
  {
    category: PresetCategory.MATERIALS,
    id: 'layered_paper'
  },
  {
    category: PresetCategory.MOVEMENTS,
    id: 'synthwave'
  },
  {
    category: PresetCategory.TECHNIQUES,
    id: 'painting'
  },
  {
    category: PresetCategory.THEMES,
    id: 'digital_art'
  },
  {
    category: PresetCategory.THEMES,
    id: 'hyper_realistic'
  }
] as const

function getTranslateStylePresetMap(presetCategory: PresetCategory) {
  switch (presetCategory) {
    case PresetCategory.MOVEMENTS:
      return {
        offsetX: 122,
        offsetY: 244,
        size: 81,
        scale: 0.51
      }
    case PresetCategory.THEMES:
      return {
        offsetX: 120,
        offsetY: 279,
        size: 80,
        scale: 0.5
      }
    case PresetCategory.TECHNIQUES:
      return {
        offsetX: 122,
        offsetY: 243,
        size: 81,
        scale: 0.51
      }
    case PresetCategory.EFFECTS:
    case PresetCategory.MATERIALS:
      return {
        offsetX: 121,
        offsetY: 121,
        size: 81,
        scale: 0.505
      }
    default:
      return {
        offsetX: 121,
        offsetY: 80,
        size: 81,
        scale: 0.505
      }
  }
}

function getRowAndColumn(index: number, columns = 4) {
  const row = Math.floor(index / columns)
  const column = index % columns
  return { row, column }
}

function getTranslateStyle(
  index: number,
  preset: Exclude<PresetCategory, PresetCategory.ALL | PresetCategory.POPULAR>
) {
  const { offsetX, offsetY, size, scale } = getTranslateStylePresetMap(preset)
  const { row, column } = getRowAndColumn(index)
  const x = offsetX - column * size
  const y = offsetY - row * size

  return `translate(${x}px, ${y}px) scale(${scale}, ${scale})`
}

export function uploadReferenceImg() {
  const fileInput = document.getElementById(
    'style-image-reference-file-input'
  ) as HTMLInputElement | null
  if (!fileInput) return

  fileInput.value = '' // Clear input value to allow the same uploaded file to trigger an event
  fileInput.click()
}

const FireflyPanel = () => {
  const {
    contentClass,
    stylePresets,
    addStylePreset,
    removeStylePreset,
    setPropertyState,
    selectedPresetCategory,
    setSelectedPresetCategory,
    styleImageReference,
    setShowUploadingImageRightsDialog,
    clearStylePresets,
    hasViewedUploadingImageRightsDialog,
    styleStrength,
    guideStrength,
    structureStrength,
    visualIntensity
  } = useFirefly()

  const maxMediumSize = useMaxMediumSizePreference()

  const fireflyStyleReferenceCollapsed = useProjectState(
    'fireflyStyleReferenceCollapsed'
  )
  const fireflyStyleEffectsCollapsed = useProjectState(
    'fireflyStyleEffectsCollapsed'
  )
  const fireflyGeneralSettingsCollapsed = useProjectState(
    'fireflyGeneralSettingsCollapsed'
  )
  const fireflyAdminSettingsCollapsed = useProjectState(
    'fireflyAdminSettingsCollapsed'
  )
  const { setPanelAccordionItem } = useProjectActions()

  const flags = useFlags()

  const styleReferences = useStyleReferences()

  const substance3DMaterials = useSubstance3DMaterials()

  function handleUploadReferenceImageClick() {
    if (hasViewedUploadingImageRightsDialog) {
      uploadReferenceImg()
    } else {
      setShowUploadingImageRightsDialog(true)
    }
  }

  function handleReferenceImgFileInput(e: ChangeEvent<HTMLInputElement>) {
    if (!e.target.files?.length) return

    if (styleImageReference?.type === 'user-upload') {
      URL.revokeObjectURL(styleImageReference.url)
    }

    setPropertyState({
      key: 'styleImageReference',
      value: {
        type: 'user-upload',
        url: URL.createObjectURL(e.target.files[0])
      }
    })
  }

  function handleStyleImageReferenceClicked(
    type: StyleImageReference['type'],
    url: string
  ) {
    const deselectSelectedReference =
      styleImageReference?.type === type && styleImageReference.url === url

    if (
      deselectSelectedReference &&
      styleImageReference.type === 'user-upload'
    ) {
      URL.revokeObjectURL(styleImageReference.url)
    }

    setPropertyState({
      key: 'styleImageReference',
      value: deselectSelectedReference ? null : { type, url }
    })
  }

  function closeOverlayPopover(e: React.MouseEvent<HTMLElement, MouseEvent>) {
    e.currentTarget.dispatchEvent(OverlayCloseEvent)
  }

  return (
    <Panel>
      <PanelHeader textAlign="center">Scene to image</PanelHeader>
      <PanelContent>
        <input
          id="style-image-reference-file-input"
          type="file"
          accept="image/*"
          onChange={handleReferenceImgFileInput}
          style={{
            display: 'none'
          }}
        />

        <PanelAccordion
          label="Style reference"
          tooltip="Add a reference image to match its style"
          collapsed={fireflyStyleReferenceCollapsed}
          onChange={collapsed =>
            setPanelAccordionItem({
              key: 'fireflyStyleReferenceCollapsed',
              collapsed
            })
          }>
          <div className="flex gap-m align-center">
            <div
              className={cn(styles['thumbnail-container'], {
                [styles['selected']]: !!styleImageReference
              })}
              onClick={handleUploadReferenceImageClick}>
              <Icon slot="icon" className="icon-m">
                <ImagePlaceholderIcon />
              </Icon>

              {styleImageReference && (
                <>
                  <FireflyThumbnail
                    className={styles['selected-style-reference-thumbnail']}
                    image={styleImageReference.url}
                  />
                  <ActionButton
                    className={styles['selected-style-reference-clear-btn']}
                    onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      handleStyleImageReferenceClicked(
                        styleImageReference.type,
                        styleImageReference.url
                      )
                    }}>
                    <Icon slot="icon">
                      <CloseIcon />
                    </Icon>
                  </ActionButton>
                </>
              )}
            </div>
            <div className="flex flex-1 flex-col" style={{ gap: 4 }}>
              <ActionButton
                size={maxMediumSize}
                onClick={handleUploadReferenceImageClick}>
                <Icon slot="icon" style={{ marginRight: -6 }}>
                  <UploadCloudIcon />
                </Icon>
                Upload image
              </ActionButton>
              <ActionButton
                size={maxMediumSize}
                id="style-reference-image-gallery-action-btn">
                <Icon slot="icon" style={{ marginRight: -5 }}>
                  <BinocularsIcon />
                </Icon>
                Browse gallery
              </ActionButton>
              {flags['base-pf-ui-substance-materials'] && (
                <ActionButton
                  size={maxMediumSize}
                  className={styles['substance-3d-action-btn']}
                  id="substance-3d-material-image-gallery-action-btn">
                  <Icon slot="icon">
                    <Substance3DIcon />
                  </Icon>
                  Substance materials
                </ActionButton>
              )}
              {/* Reference library */}

              <Overlay
                trigger="style-reference-image-gallery-action-btn@click"
                placement="left"
                offset={15}>
                <Popover className={styles['popover']}>
                  <div className={styles['popover-content']}>
                    <PanelSectionHeader sectionHeader="Reference image gallery"></PanelSectionHeader>

                    <div className="flex flex-col gap-m">
                      {styleReferences?.styles.map(style => {
                        return (
                          <React.Fragment key={style.id}>
                            <span className={styles['popover-header']}>
                              {style.title.defaultMessage}
                            </span>
                            <div className={styles['popular-prompts']}>
                              {style.images.map(img => {
                                const url = `${process.env.NEXT_PUBLIC_CLIENT_FIREFLY_ASSET_ENDPOINT}/image-style-zeros/v3/${img.url}`

                                return (
                                  <FireflyThumbnail
                                    key={img.id}
                                    image={url}
                                    selected={url === styleImageReference?.url}
                                    onClick={e => {
                                      closeOverlayPopover(e)
                                      handleStyleImageReferenceClicked(
                                        'preset',
                                        url
                                      )
                                    }}
                                  />
                                )
                              })}
                            </div>
                          </React.Fragment>
                        )
                      })}
                    </div>
                  </div>
                </Popover>
              </Overlay>
            </div>
          </div>

          <Overlay
            trigger="substance-3d-material-image-gallery-action-btn@click"
            placement="left"
            offset={15}>
            <Popover className={styles['popover']}>
              <div className={styles['popover-content']}>
                <PanelSectionHeader sectionHeader="Substance material gallery">
                  <Substance3DAssetsIcon style={{ height: 32, width: 32 }} />
                </PanelSectionHeader>

                <div className="flex flex-col gap-m">
                  {substance3DMaterials?.map(({ category, items }) => {
                    return (
                      <React.Fragment key={category}>
                        <span className={styles['popover-header']}>
                          {category}
                        </span>
                        <div className={styles['popular-prompts']}>
                          {items.map(({ id, thumbnail }) => {
                            const url = `${thumbnail.url}?height=85&width=85`

                            return (
                              <FireflyThumbnail
                                key={id}
                                image={url}
                                selected={url === styleImageReference?.url}
                                onClick={e => {
                                  closeOverlayPopover(e)
                                  handleStyleImageReferenceClicked(
                                    'preset',
                                    url
                                  )
                                }}
                              />
                            )
                          })}
                        </div>
                      </React.Fragment>
                    )
                  })}
                </div>
              </div>
            </Popover>
          </Overlay>
        </PanelAccordion>

        <PanelAccordion
          label="Style effects"
          collapsed={fireflyStyleEffectsCollapsed}
          onChange={collapsed =>
            setPanelAccordionItem({
              key: 'fireflyStyleEffectsCollapsed',
              collapsed
            })
          }>
          <div className={styles['reference-effects-container']}>
            <OverlayTrigger placement="left" offset={15}>
              <div className={styles['thumbnail-container']} slot="trigger">
                <Icon slot="icon" className="icon-m">
                  <AddIcon />
                </Icon>
              </div>
              <Popover slot="click-content" className={styles['popover']}>
                <div className={styles['popover-content']}>
                  <PanelSectionHeader sectionHeader="Effects"></PanelSectionHeader>

                  <ActionGroup
                    selects="single"
                    size={maxMediumSize}
                    selected={[selectedPresetCategory]}
                    change={e => {
                      const keys = (
                        e as unknown as {
                          target: { selected: PresetCategory[] }
                        }
                      ).target.selected
                      if (!keys.length) return

                      setSelectedPresetCategory(keys[0])
                    }}>
                    {PresetCategories.map(key => (
                      <ActionButton key={key} value={key}>
                        {key.replaceAll('_', ' ')}
                      </ActionButton>
                    ))}
                  </ActionGroup>
                  <div className="flex flex-col gap-xs">
                    {PresetCategories.map(key => {
                      if (key === PresetCategory.ALL) {
                        return null
                      }
                      if (selectedPresetCategory !== PresetCategory.ALL) {
                        if (selectedPresetCategory !== key) {
                          return null
                        }
                      }
                      if (
                        selectedPresetCategory === PresetCategory.ALL &&
                        key === PresetCategory.POPULAR
                      ) {
                        return null
                      }

                      if (key === PresetCategory.POPULAR) {
                        return (
                          <div
                            key={key}
                            style={{
                              display: 'grid',
                              marginLeft: -8,
                              gridTemplateColumns:
                                'repeat(auto-fill,minmax(84px, 1fr))'
                            }}>
                            {PopularPresets.map(({ category, id }) => {
                              const presetCategory = StylePresets.find(
                                p => p.name === category
                              )
                              if (!presetCategory) return null

                              const index = presetCategory.children.findIndex(
                                child => child.id === id
                              )
                              if (index === -1 || index === undefined)
                                return null

                              const isSelected = stylePresets.some(
                                preset => preset === id
                              )
                              return (
                                <SelectionCardItem
                                  key={id}
                                  label={presetCategory.children[index].name}
                                  imgSrc={`/firefly/${category.toLowerCase()}.jpg`}
                                  imgStyle={{
                                    transform: getTranslateStyle(
                                      presetCategory.children[index].index ===
                                        undefined
                                        ? index
                                        : presetCategory.children[index].index,
                                      category as Exclude<
                                        PresetCategory,
                                        | PresetCategory.ALL
                                        | PresetCategory.POPULAR
                                      >
                                    )
                                  }}
                                  selected={isSelected}
                                  onClick={() => {
                                    isSelected
                                      ? removeStylePreset(id)
                                      : addStylePreset(id)
                                  }}
                                />
                              )
                            })}
                          </div>
                        )
                      }
                      return (
                        <Fragment key={key}>
                          {selectedPresetCategory === PresetCategory.ALL && (
                            <span className={styles['popover-effect-header']}>
                              {key.replaceAll('_', ' ')}
                            </span>
                          )}
                          <div
                            style={{
                              display: 'grid',
                              gridTemplateColumns:
                                'repeat(auto-fill,minmax(84px, 1fr))'
                            }}>
                            {StylePresets.find(
                              style => style.name === key
                            )?.children?.map(({ id, name, index }, i) => {
                              const isSelected = stylePresets.some(
                                preset => preset === id
                              )
                              return (
                                <SelectionCardItem
                                  key={id}
                                  label={name}
                                  imgSrc={`/firefly/${key.toLowerCase()}.jpg`}
                                  imgStyle={{
                                    transform: getTranslateStyle(
                                      index === undefined ? i : index,
                                      key as Exclude<
                                        PresetCategory,
                                        | PresetCategory.ALL
                                        | PresetCategory.POPULAR
                                      >
                                    )
                                  }}
                                  selected={isSelected}
                                  onClick={() => {
                                    isSelected
                                      ? removeStylePreset(id)
                                      : addStylePreset(id)
                                  }}
                                />
                              )
                            })}
                          </div>
                        </Fragment>
                      )
                    })}
                  </div>
                </div>
              </Popover>
            </OverlayTrigger>

            {stylePresets.map(preset => {
              const category = StylePresets.find(({ children }) => {
                return children.some(({ id }) => id === preset)
              })

              const presetItemIndex = category?.children.findIndex(
                ({ id }) => id === preset
              )

              if (
                !category ||
                presetItemIndex === -1 ||
                presetItemIndex === undefined
              )
                return null

              const presetItem = category.children[presetItemIndex]
              return (
                <SelectionCardItem
                  key={preset}
                  label={presetItem.name}
                  imgSrc={`/firefly/${category.name.toLowerCase()}.jpg`}
                  imgStyle={{
                    transform: getTranslateStyle(
                      presetItem.index === undefined
                        ? presetItemIndex
                        : presetItem.index,
                      category.name as Exclude<
                        PresetCategory,
                        PresetCategory.ALL | PresetCategory.POPULAR
                      >
                    )
                  }}
                  onClose={() => {
                    removeStylePreset(preset)
                  }}
                />
              )
            })}
          </div>

          {!!stylePresets.length && (
            <ActionButton
              onClick={() => clearStylePresets()}
              size={maxMediumSize}
              style={{ alignSelf: 'flex-end' }}>
              Clear all
            </ActionButton>
          )}
        </PanelAccordion>

        <PanelAccordion
          label="Content type"
          collapsed={fireflyGeneralSettingsCollapsed}
          onChange={collapsed =>
            setPanelAccordionItem({
              key: 'fireflyGeneralSettingsCollapsed',
              collapsed
            })
          }>
          <div className={styles['content-type-picker-wrapper']}>
            <ActionGroup
              size={maxMediumSize}
              selects="single"
              selected={[contentClass]}
              change={e => {
                const keys = (
                  e as unknown as { target: { selected: ContentClass[] } }
                ).target.selected
                if (!keys.length) return
                setPropertyState({
                  key: 'contentClass',
                  value: keys[0]
                })
              }}>
              <ActionButton value={ContentClass.ART} aria-label="art">
                <Icon slot="icon">
                  <BrushIcon />
                </Icon>
                Art
              </ActionButton>
              <ActionButton value={ContentClass.PHOTO} aria-label="photo">
                <Icon slot="icon">
                  <CameraIcon />
                </Icon>
                Photo
              </ActionButton>

              {flags['pf-ui-firefly-content-type-vector-option'] && (
                <ActionButton value={ContentClass.VECTOR} aria-label="vector">
                  Vector
                </ActionButton>
              )}
            </ActionGroup>
            <Switch
              size={maxMediumSize}
              checked={contentClass === ContentClass.AUTO}
              onClick={() =>
                setPropertyState({
                  key: 'contentClass',
                  value:
                    contentClass === ContentClass.AUTO
                      ? ContentClass.ART
                      : ContentClass.AUTO
                })
              }>
              Auto
            </Switch>
          </div>

          {/* TODO - add color scene slider */}
          {/* <div>
            <div className="flex justify-between align-center">
              <FieldLabel size="l">Match scene colors</FieldLabel>
              <FieldLabel size="l">{`${sceneColorsAdherence}%`}</FieldLabel>
            </div>
            <Slider
              className={styles['slider']}
              min={0}
              max={100}
              step={1}
              label="Match scene colors"
              aria-label="Match scene colors"
              value={50}
              onInput={e => {
                const value = (e as unknown as { target: { value: number } })
                  .target.value
                setPropertyState({
                  key: 'sceneColorsAdherence',
                  value: value
                })
              }}
              variant="filled"
              label-visibility="none"
            />
          </div> */}
        </PanelAccordion>

        {flags['base-tf-admin-edit-project'] && (
          <PanelAccordion
            label="Admin settings"
            collapsed={fireflyAdminSettingsCollapsed}
            onChange={collapsed =>
              setPanelAccordionItem({
                key: 'fireflyAdminSettingsCollapsed',
                collapsed
              })
            }>
            <Slider
              size={maxMediumSize}
              min={0}
              max={100}
              step={50}
              label="Style strength"
              aria-label="Style strength"
              value={styleStrength}
              onInput={e => {
                const value = (e as unknown as { target: { value: number } })
                  .target.value
                setPropertyState({
                  key: 'styleStrength',
                  value: value
                })
              }}
              variant="filled"
            />
            <Slider
              size={maxMediumSize}
              min={0}
              max={100}
              step={1}
              label="Guide strength"
              aria-label="Style strength"
              value={guideStrength}
              onInput={e => {
                const value = (e as unknown as { target: { value: number } })
                  .target.value
                setPropertyState({
                  key: 'guideStrength',
                  value: value
                })
              }}
              variant="filled"
            />
            <Slider
              size={maxMediumSize}
              min={0}
              max={100}
              step={10}
              label="Structure reference strength"
              aria-label="Structure reference strength"
              value={structureStrength}
              onInput={e => {
                const value = (e as unknown as { target: { value: number } })
                  .target.value
                setPropertyState({
                  key: 'structureStrength',
                  value: value
                })
              }}
              variant="filled"
            />
            <Slider
              size={maxMediumSize}
              min={2}
              max={10}
              step={1}
              label="Style visual intensity"
              aria-label="Style visual intensity"
              value={visualIntensity}
              onInput={e => {
                const value = (e as unknown as { target: { value: number } })
                  .target.value
                setPropertyState({
                  key: 'visualIntensity',
                  value: value
                })
              }}
              variant="filled"
            />
          </PanelAccordion>
        )}
        <a
          className={styles['terms']}
          href="https://www.adobe.com/legal/licenses-terms/adobe-gen-ai-user-guidelines.html"
          target="_blank">
          Adobe Generative AI terms
        </a>

        <div className={styles['content']}></div>
      </PanelContent>
    </Panel>
  )
}

export default FireflyPanel
