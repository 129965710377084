import { useRef, useState } from 'react'

type SelfMaintainState = {
  show: boolean
  setShow: (val: boolean) => void
}

export const useShowPopoverOnHover = (
  selfMaintainState?: SelfMaintainState
) => {
  const [show, setShow] = useState(false)

  const timeout = useRef<NodeJS.Timeout>()

  function showPopover() {
    timeout.current && clearTimeout(timeout.current)
    selfMaintainState?.setShow ? selfMaintainState.setShow(true) : setShow(true)
  }

  function delayPopoverClose() {
    timeout.current = setTimeout(() => {
      selfMaintainState?.setShow
        ? selfMaintainState.setShow(false)
        : setShow(false)
    }, 800)
  }

  function onPopoverMouseEnter() {
    timeout.current && clearTimeout(timeout.current)
  }

  function closePopover() {
    timeout.current && clearTimeout(timeout.current)
    selfMaintainState?.setShow
      ? selfMaintainState.setShow(false)
      : setShow(false)
  }

  return {
    show,
    onTriggerMouseOver: showPopover,
    onTriggerMouseOut: delayPopoverClose,
    onPopoverMouseLeave: delayPopoverClose,
    onPopoverMouseEnter,
    closePopover
  }
}
