import React, { FC } from 'react'
import { Button } from 'ui'
import styles from '@styles/components/ShareExportFooter.module.scss'
import { useMediumMinimumSizePreference } from '@hooks/useProject'

interface Props {
  exportDisabled?: boolean
  onExportClick: () => void
  buttonText: string
}

const ShareExportFooter: FC<Props> = ({
  exportDisabled,
  onExportClick,
  buttonText
}) => {
  const size = useMediumMinimumSizePreference()

  return (
    <div className={styles.container}>
      <Button
        size={size}
        disabled={exportDisabled}
        onclick={onExportClick}
        style={{
          width: '100%'
        }}>
        {buttonText}
      </Button>
    </div>
  )
}

export default ShareExportFooter
