import { NextRouter } from 'next/router'
import { Button, ProgressCircle, Tab, TabPanel, Tabs } from 'ui'
import UserProfile from '@components/authentication/UserProfile'
import { CustomLink } from './CustomLink'
import { CommunityAppBarProps } from './CommunityAppBar'
import { useAuth } from '@hooks/useAuth'
import styles from '@styles/components/CommunityAppBar.module.scss'
import { useFlags } from 'launchdarkly-react-client-sdk'
import AdobeIMS from '@services/auth/IMS'

export const CommunityAppBarUserControls = () => {
  const flags = useFlags()
  const susiLightEnabled = flags['base-pf-ui-login-modal']
  const { setShowSignUpSignInModal, isAuthenticationStateLoaded, status } =
    useAuth()

  const loading = !isAuthenticationStateLoaded || status === 'CHECKING_STATUS'
  const authenticated =
    isAuthenticationStateLoaded && status === 'AUTHENTICATED'
  const unauthenticated =
    isAuthenticationStateLoaded &&
    (status === 'ERROR_CHECKING_STATUS' || status === 'UNAUTHENTICATED')

  const login = () => AdobeIMS.signIn({ redirect_uri: window.location.href })
  const openSusiLightDialog = () => setShowSignUpSignInModal(true)
  const loginAction = susiLightEnabled ? openSusiLightDialog : login

  if (loading) {
    return <ProgressCircle indeterminate size="s" />
  }

  if (authenticated) {
    return (
      <div className={styles['user-profile-container']}>
        <UserProfile themeColor={{ trigger: 'light', dialog: 'light' }} />
      </div>
    )
  }

  if (unauthenticated) {
    return (
      <Button variant="primary" onClick={loginAction}>
        Log In
      </Button>
    )
  }

  return (
    <Button variant="primary" onClick={loginAction}>
      Log In
    </Button>
  )
}

export const CommunityAppBarRouteSwitcher = ({
  isAuthenticated,
  router,
  vertical
}: Pick<CommunityAppBarProps, 'isAuthenticated'> & {
  router: NextRouter
  vertical: boolean
}) => {
  const routeTabs: Array<{ name: string; href: string }> = [
    {
      name: 'Community',
      href: '/discover'
    }
  ].concat(
    isAuthenticated
      ? {
          name: 'Your files',
          href: '/my-projects'
        }
      : []
  )

  function handleTabChange(event: any) {
    const selectedTabValue = event.target?.selected
    const isValidRouteTab = routeTabs.some(tab => tab.href === selectedTabValue)
    if (isValidRouteTab) {
      router.push(selectedTabValue)
    }
  }

  return (
    <Tabs
      direction={vertical ? 'vertical' : 'horizontal'}
      selected={router.route}
      quiet
      aria-label="Links to community page and your project dashboard"
      change={handleTabChange}>
      {routeTabs.map(item => (
        <Tab
          role="tab"
          key={'route-tab-' + item.href}
          value={item.href}
          className={styles['tab']}>
          <CustomLink key={`community-links-${item.href}`} href={item.href}>
            {item.name}
          </CustomLink>
        </Tab>
      ))}
      {routeTabs.map(item => (
        <TabPanel key={'tab-panel-' + item.href} value={item.href} />
      ))}
    </Tabs>
  )
}
