import React, { FC, useEffect, useState } from 'react'
import Router from 'next/router'
import cn from 'classnames'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Button, Dialog, DialogBase, Divider } from 'ui'
import styles from '@styles/components/StudioAppBar.module.scss'
import { useAuth } from '@hooks/useAuth'
import { useIsProjectViewOnly, useProjectState } from '@hooks/useProject'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import { useCopyProject } from '@hooks/useCopyProject'
import ConnectedAvatars from '@components/sync/ConnectedAvatars'
import ShareExportDialog from '@components/share/ShareExportDialog'
import HelpDialog from '@components/studio/HelpDialog'
import AvatarsVisibilityToggle from '@components/propertiesPanel/AvatarsVisibilityToggle'
import UndoRedo from '@components/propertiesPanel/UndoRedo'
import DocumentSnapshotStatusIndicator from '@components/connectionStatus/DocumentSnapshotStatusIndicator'
import Feedback from '@components/feedback/Feedback'
import DownloadExportDialog from '@components/share/DownloadExportDialog'
import StudioAppBarMainMenu from './StudioAppBarMainMenu'
import CursorOverlay from '@components/cursorOverlay/CursorOverlay'
import { ClassNameProps } from 'types/reactProps'
import NeoIcon from '/public/neo-logo.svg'
import DevIcon from '/public/header-badge-dev.svg'
import StudioAppBarUserProfile from './StudioAppBarUserProfile'
import StudioAppBarProjectName from './StudioAppBarProjectName'
import GroupExpand from '@components/propertiesPanel/GroupExpand'
import ObjectSnappingToggleButton from './ObjectSnappingToggleButton'
import InputDeviceMenu from './InputDeviceMenu'
import { StudioAppBarAdminMenu } from './StudioAppBarAdminMenu'
import SharedBadge from '@components/project/SharedBadge'
import ViewOnlyBadge from '@components/project/ViewOnlyBadge'

const StudioAppBar: FC<ClassNameProps> = ({ className }) => {
  const flags = useFlags()

  const { localUser } = useAuth()

  const isFeatured = useProjectState('isFeatured')
  const isPublic = useProjectState('isPublic')
  const ownerUserUuid = useProjectState('ownerUserUuid')
  const showFireflyPopover = useProjectState('showFireflyPopover')
  const sizePreference = useProjectState('sizePreference')

  const isProjectViewOnly = useIsProjectViewOnly()

  const sharedBadgeSize = sizePreference === 'l' ? 'm' : 's'

  const engineState = useSceneState('engineState')
  const canvasAnimationStartedForRecording = useSceneState(
    'canvasAnimationStartedForRecording'
  )

  const { setEngineState } = useSceneActions()

  const {
    status: copyProjectStatus,
    copyProject,
    createdProjectUuid
  } = useCopyProject()

  const [
    showDuplicateProjectWarningDialog,
    setShowDuplicateProjectWarningDialog
  ] = useState<{ show: boolean; navigateTo?: 'back' | 'discover' }>({
    show: false,
    navigateTo: undefined
  })

  const isProjectOwner = localUser?.uuid === ownerUserUuid

  const hasInitializedProjectStoreState = !!localUser && !!ownerUserUuid

  useEffect(() => {
    if (copyProjectStatus === 'duplicated' && createdProjectUuid) {
      setShowDuplicateProjectWarningDialog({ show: false })
      Router.push(`/studio/${createdProjectUuid}`)
    }
  }, [copyProjectStatus, createdProjectUuid])

  useEffect(() => {
    if (isFeatured === null) return

    // Show alert if user tries to leave page that changes won't be saved
    const onBeforeunload = (e: BeforeUnloadEvent) => {
      e.preventDefault()
      e.returnValue = ''
    }

    if (isFeatured) {
      window.addEventListener('beforeunload', onBeforeunload)
    }

    return () => {
      if (isFeatured) {
        window.removeEventListener('beforeunload', onBeforeunload)
      }
    }
  }, [isFeatured])

  function navigateBack() {
    setEngineState('UNMOUNT')

    /**
     * Push to discover page if user had to log in before viewing studio page.
     * The user gets redirected to Adobe IMS domain if they are not logged in and then redirected back to studio page after logging in.
     * As a result the previous route history is the IMS domain
     */
    if (window.location.href.includes('#old_hash=&from_ims=true')) {
      return Router.push('/discover')
    }

    const prevPath = window.location.pathname
    Router.back()

    setTimeout(() => {
      const currentPath = window.location.pathname
      // There is no route to navigate back to if the studio page has been opened as a new tab from discover or my-project page
      if (prevPath === currentPath) {
        Router.push('/discover')
      }
    }, 1000)
  }

  function onLogoClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault()

    if (isFeatured) {
      return setShowDuplicateProjectWarningDialog({
        show: true,
        navigateTo: 'discover'
      })
    }

    navigateToDiscoverPage()
  }

  function navigateToDiscoverPage() {
    Router.push('/discover')
  }

  function handleLeaveDialogClick() {
    showDuplicateProjectWarningDialog.navigateTo === 'discover'
      ? navigateToDiscoverPage()
      : navigateBack()
    setShowDuplicateProjectWarningDialog({ show: false })
  }

  function handleDuplicateDialogClick() {
    copyProject()
  }

  return (
    <>
      <div
        className={cn(styles['studio-app-bar'], className, {
          [styles['non-interactive']]: canvasAnimationStartedForRecording
        })}>
        <div className={styles['grouped-content']}>
          <a
            className={styles['app-logo']}
            href={`${process.env.NEXT_PUBLIC_CLIENT_WEB_HOST}/discover`}
            onClick={onLogoClick}>
            <NeoIcon />
          </a>

          <p className={styles['beta']}>(Beta)</p>

          {process.env.NEXT_PUBLIC_CLIENT_APP_ENVIRONMENT === 'dev' && (
            <DevIcon />
          )}

          <StudioAppBarMainMenu navigateHome={navigateToDiscoverPage} />

          <StudioAppBarProjectName
            className={styles['project-name']}
            isProjectOwner={isProjectOwner}
          />

          {hasInitializedProjectStoreState &&
            engineState === 'INITIALIZED_AND_DOCUMENT_LOADED' && (
              <DocumentSnapshotStatusIndicator />
            )}

          {isPublic && isProjectOwner && <SharedBadge size={sharedBadgeSize} />}
          {isProjectViewOnly && <ViewOnlyBadge size={sharedBadgeSize} />}

          {!isProjectOwner && isFeatured && hasInitializedProjectStoreState && (
            <Button
              pending={copyProjectStatus === 'duplicating'}
              onClick={copyProject}
              variant="secondary">
              Duplicate Project
            </Button>
          )}
        </div>

        <div className={styles['grouped-content']}>
          <UndoRedo />
          <InputDeviceMenu />
          {flags['base-tf-fx-snapping'] && <ObjectSnappingToggleButton />}
          {flags['base-pf-ui-group-expand'] && <GroupExpand />}
          <Divider className={'vertical-divider'} vertical />
          <ConnectedAvatars />
          <AvatarsVisibilityToggle />
          {!showFireflyPopover && (
            <>
              <ShareExportDialog />
              <DownloadExportDialog />
            </>
          )}
          <Feedback />
          <Divider className={'vertical-divider'} vertical />
          <HelpDialog />
          <StudioAppBarUserProfile />
          {flags['base-tf-admin-edit-project'] && <StudioAppBarAdminMenu />}
        </div>

        <CursorOverlay />
      </div>

      {isFeatured && (
        <DialogBase
          className={styles['duplicate-project-warning-dialog-base']}
          underlay
          open={showDuplicateProjectWarningDialog.show}>
          <Dialog size="s">
            <h2 slot="heading">You are leaving the page</h2>
            <p>
              Your changes won't be saved. Duplicate project to save changes.
            </p>

            <div className={styles['duplicate-project-warning-dialog-footer']}>
              <Button
                variant="secondary"
                treatment="outline"
                disabled={copyProjectStatus === 'duplicating'}
                onClick={handleLeaveDialogClick}>
                Leave
              </Button>

              <Button
                slot="button"
                pending={copyProjectStatus === 'duplicating'}
                onClick={handleDuplicateDialogClick}
                variant="primary">
                Duplicate Project
              </Button>
            </div>
          </Dialog>
        </DialogBase>
      )}
    </>
  )
}

export default StudioAppBar
