import React, { FC, memo } from 'react'
import { Icon, Picker, FieldLabel, MenuItem, NumberField } from 'ui'
import { PropertyInputSlider } from '@components/slider/FirstAndSubsequentInputSlider'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import { useProjectState } from '@hooks/useProject'
import {
  EngineCommitChange,
  EngineRepeatAngleDirection,
  EngineRepeatType
} from '@services/engine/types'
import RepeatDuplicateRadialIcon from '/public/s2_icon_duplicate_radial.svg'
import RepeatDuplicateLinearIcon from '/public/s2_icon_duplicate_linear.svg'

const Repeat: FC = () => {
  const repeatType = useSceneState('repeatType')
  const address = useSceneState('address')
  const repeatDistance = useSceneState('repeatDistance')
  const repeatDistanceX = useSceneState('repeatDistanceX')
  const repeatDistanceY = useSceneState('repeatDistanceY')
  const repeatDistanceZ = useSceneState('repeatDistanceZ')
  const repeatX = useSceneState('repeatX')
  const repeatY = useSceneState('repeatY')
  const repeatZ = useSceneState('repeatZ')
  const repeatAngle = useSceneState('repeatAngle')
  const repeatAngleDirection = useSceneState('repeatAngleDirection')

  const { setPropertyState } = useSceneActions()

  const sizePreference = useProjectState('sizePreference')

  return (
    <>
      <div className="flex flex-col">
        <FieldLabel for="repeat-type">Array</FieldLabel>
        <Picker
          id="repeat-type"
          size={sizePreference}
          className="picker-min-width w-auto"
          value={repeatType.toString()}
          onchange={e => {
            const value = parseInt(
              (e as unknown as { target: { value: string } }).target.value
            )
            setPropertyState({
              address,
              key: 'repeatType',
              value
            })
            document.getElementById('canvas')?.focus()
          }}
          label="Selection repeat type">
          <MenuItem
            key={EngineRepeatType.NONE.toString()}
            value={EngineRepeatType.NONE.toString()}>
            None
          </MenuItem>
          <MenuItem
            key={EngineRepeatType.XYZ.toString()}
            value={EngineRepeatType.XYZ.toString()}>
            <Icon slot="icon">
              <RepeatDuplicateLinearIcon />
            </Icon>
            Linear
          </MenuItem>
          <MenuItem
            key={EngineRepeatType.ANGLE.toString()}
            value={EngineRepeatType.ANGLE.toString()}>
            <Icon slot="icon">
              <RepeatDuplicateRadialIcon />
            </Icon>
            Radial
          </MenuItem>
        </Picker>
      </div>
      {repeatType === EngineRepeatType.XYZ && (
        <>
          <div className="flex gap-xs">
            <div className="flex flex-col justify-end flex-1">
              <PropertyInputSlider
                size={sizePreference}
                variant="filled"
                min={0}
                max={1}
                step={0.001}
                label="Distance X"
                value={repeatDistanceX}
                onInput={e => {
                  const value = (e as unknown as { target: { value: number } })
                    .target.value
                  setPropertyState({
                    address,
                    key: 'repeatDistanceX',
                    value
                  })
                }}
                onMouseDown={e => {
                  const value = (e as unknown as { target: { value: number } })
                    .target.value
                  setPropertyState({
                    key: 'repeatDistanceX',
                    value,
                    commit: EngineCommitChange.BEGIN_COMMIT
                  })
                }}
                onMouseUp={e => {
                  const value = (e as unknown as { target: { value: number } })
                    .target.value
                  setPropertyState({
                    key: 'repeatDistanceX',
                    value,
                    commit: EngineCommitChange.END_COMMIT
                  })
                }}
                editable
                hideStepper
              />
            </div>

            <div className="flex flex-col">
              <FieldLabel for="repeat-x-copies">Copies</FieldLabel>
              <NumberField
                className="object-properties-repeat-copy-number-field-width"
                id="repeat-x-copies"
                size={sizePreference}
                min={1}
                step={1}
                value={repeatX}
                onChange={e => {
                  const value = (e as unknown as { target: { value: number } })
                    .target.value
                  setPropertyState({
                    address,
                    key: 'repeatX',
                    value
                  })
                }}
              />
            </div>
          </div>

          <div className="flex gap-xs">
            <div className="flex flex-col justify-end flex-1">
              <PropertyInputSlider
                size={sizePreference}
                variant="filled"
                min={0}
                max={1}
                step={0.001}
                label="Distance Y"
                value={repeatDistanceY}
                onInput={e => {
                  const value = (e as unknown as { target: { value: number } })
                    .target.value
                  setPropertyState({
                    address,
                    key: 'repeatDistanceY',
                    value
                  })
                }}
                onMouseDown={e => {
                  const value = (e as unknown as { target: { value: number } })
                    .target.value
                  setPropertyState({
                    key: 'repeatDistanceY',
                    value,
                    commit: EngineCommitChange.BEGIN_COMMIT
                  })
                }}
                onMouseUp={e => {
                  const value = (e as unknown as { target: { value: number } })
                    .target.value
                  setPropertyState({
                    key: 'repeatDistanceY',
                    value,
                    commit: EngineCommitChange.END_COMMIT
                  })
                }}
                editable
                hideStepper
              />
            </div>
            <div className="flex flex-col">
              <FieldLabel for="repeat-y-copies">Copies</FieldLabel>
              <NumberField
                className="object-properties-repeat-copy-number-field-width"
                id="repeat-y-copies"
                size={sizePreference}
                min={1}
                step={1}
                value={repeatY}
                onChange={e => {
                  const value = (e as unknown as { target: { value: number } })
                    .target.value
                  setPropertyState({
                    address,
                    key: 'repeatY',
                    value
                  })
                }}
              />
            </div>
          </div>

          <div className="flex gap-xs">
            <div className="flex flex-col justify-end flex-1">
              <PropertyInputSlider
                size={sizePreference}
                variant="filled"
                min={0}
                max={1}
                step={0.001}
                label="Distance Z"
                value={repeatDistanceZ}
                onInput={e => {
                  const value = (e as unknown as { target: { value: number } })
                    .target.value
                  setPropertyState({
                    address,
                    key: 'repeatDistanceZ',
                    value
                  })
                }}
                onMouseDown={e => {
                  const value = (e as unknown as { target: { value: number } })
                    .target.value
                  setPropertyState({
                    key: 'repeatDistanceZ',
                    value,
                    commit: EngineCommitChange.BEGIN_COMMIT
                  })
                }}
                onMouseUp={e => {
                  const value = (e as unknown as { target: { value: number } })
                    .target.value
                  setPropertyState({
                    key: 'repeatDistanceZ',
                    value,
                    commit: EngineCommitChange.END_COMMIT
                  })
                }}
                editable
                hideStepper
              />
            </div>
            <div className="flex flex-col">
              <FieldLabel for="repeat-z-copies">Copies</FieldLabel>
              <NumberField
                className="object-properties-repeat-copy-number-field-width"
                id="repeat-z-copies"
                size={sizePreference}
                min={1}
                step={1}
                value={repeatZ}
                onChange={e => {
                  const value = (e as unknown as { target: { value: number } })
                    .target.value
                  setPropertyState({
                    address,
                    key: 'repeatZ',
                    value
                  })
                }}
              />
            </div>
          </div>
        </>
      )}

      {repeatType === EngineRepeatType.ANGLE && (
        <>
          <div className="flex flex-col">
            <FieldLabel for="repeat-angle-direction">Direction</FieldLabel>
            <Picker
              id="repeat-angle-direction"
              size={sizePreference}
              className="picker-min-width w-auto"
              value={repeatAngleDirection.toString()}
              change={e => {
                const value = parseInt(
                  (
                    e as unknown as {
                      target: { value: string }
                    }
                  ).target.value
                )
                setPropertyState({
                  address,
                  key: 'repeatAngleDirection',
                  value
                })
                document.getElementById('canvas')?.focus()
              }}
              label="Selection repeat angle type">
              <MenuItem
                key={EngineRepeatAngleDirection.X.toString()}
                value={EngineRepeatAngleDirection.X.toString()}>
                X
              </MenuItem>
              <MenuItem
                key={EngineRepeatAngleDirection.Y.toString()}
                value={EngineRepeatAngleDirection.Y.toString()}>
                Y
              </MenuItem>
              <MenuItem
                key={EngineRepeatAngleDirection.Z.toString()}
                value={EngineRepeatAngleDirection.Z.toString()}>
                Z
              </MenuItem>
            </Picker>
          </div>
          <div className="flex gap-xs">
            <div className="flex flex-col justify-end flex-1">
              <PropertyInputSlider
                size={sizePreference}
                variant="filled"
                min={0}
                max={1}
                step={0.001}
                label="Distance"
                value={repeatDistance}
                onInput={e => {
                  const value = (e as unknown as { target: { value: number } })
                    .target.value
                  setPropertyState({
                    address,
                    key: 'repeatDistance',
                    value
                  })
                }}
                onMouseDown={e => {
                  const value = (e as unknown as { target: { value: number } })
                    .target.value
                  setPropertyState({
                    key: 'repeatDistance',
                    value,
                    commit: EngineCommitChange.BEGIN_COMMIT
                  })
                }}
                onMouseUp={e => {
                  const value = (e as unknown as { target: { value: number } })
                    .target.value
                  setPropertyState({
                    key: 'repeatDistance',
                    value,
                    commit: EngineCommitChange.END_COMMIT
                  })
                }}
                editable
                hideStepper
              />
            </div>
            <div className="flex flex-col">
              <FieldLabel for="repeat-radial-copies">Copies</FieldLabel>
              <NumberField
                className="object-properties-repeat-copy-number-field-width"
                id="repeat-radial-copies"
                size={sizePreference}
                min={1}
                step={1}
                value={repeatAngle}
                onChange={e => {
                  const value = (e as unknown as { target: { value: number } })
                    .target.value
                  setPropertyState({
                    address,
                    key: 'repeatAngle',
                    value
                  })
                }}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default memo(Repeat)
