import React, { FC, memo, useState } from 'react'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import {
  ActionButton,
  Checkbox,
  FieldLabel,
  Icon,
  OverlayTrigger,
  Tooltip
} from 'ui'
import { EngineCommitChange, MaterialType } from '@services/engine/types'
import MaterialPicker from '@components/propertiesPanel/MaterialPicker'
import SaveToIcon from '/public/temp-icon-add-to-lib.svg'
import { PropertyInputSlider } from '@components/slider/FirstAndSubsequentInputSlider'
import { useProjectState, useProjectActions } from '@hooks/useProject'
import PanelAccordion from '@components/panel/PanelAccordion'
import { Section } from './ObjectProperties'

const Material: FC = () => {
  const threeDMaterialCollapsed = useProjectState('threeDMaterialCollapsed')
  const sizePreference = useProjectState('sizePreference')
  const { setPanelAccordionItem } = useProjectActions()

  const materialType = useSceneState('materialType')
  const address = useSceneState('address')
  const materialColor = useSceneState('materialColor')
  const materialEColorLig = useSceneState('materialEColorLig')
  const materialEColorTop = useSceneState('materialEColorTop')
  const materialEColorSha = useSceneState('materialEColorSha')
  const materialIColor = useSceneState('materialIColor')
  const materialIEmissiveColor = useSceneState('materialIEmissiveColor')
  const materialKeepStylesInSync = useSceneState('materialKeepStylesInSync')
  const materialMetalness = useSceneState('materialMetalness')
  const materialRoughness = useSceneState('materialRoughness')
  const materialESpecularSize = useSceneState('materialESpecularSize')
  const materialESpecularIntensity = useSceneState('materialESpecularIntensity')
  const materialReflective = useSceneState('materialReflective')
  const materialIStrokeSize = useSceneState('materialIStrokeSize')
  const materialIHighlightIntensity = useSceneState(
    'materialIHighlightIntensity'
  )
  const materialIStrokeIntensity = useSceneState('materialIStrokeIntensity')
  const materialIColorVarIntensity = useSceneState('materialIColorVarIntensity')
  const materialIScaleX = useSceneState('materialIScaleX')
  const materialIScaleY = useSceneState('materialIScaleY')
  const materialIAngle = useSceneState('materialIAngle')

  const { setPropertyState } = useSceneActions()

  const { setShowSaveMaterialDialog } = useProjectActions()

  const isPTypeSelected = materialType === MaterialType.P
  const isETypeSelected = materialType === MaterialType.E
  const isPixelTypeSelected = materialType === MaterialType.PIXEL

  return (
    <PanelAccordion
      id={Section.appearance}
      label={
        <>
          Appearance
          <div className="position-relative">
            <div className="panel-accordion-label-button">
              <OverlayTrigger placement="bottom">
                <ActionButton
                  size={sizePreference}
                  quiet
                  slot="trigger"
                  onClick={e => {
                    e.stopPropagation()
                    setShowSaveMaterialDialog(true)
                  }}>
                  <Icon slot="icon">
                    <SaveToIcon />
                  </Icon>
                </ActionButton>
                <Tooltip slot="hover-content">Save material to library</Tooltip>
              </OverlayTrigger>
            </div>
          </div>
        </>
      }
      collapsed={threeDMaterialCollapsed}
      onChange={collapsed =>
        setPanelAccordionItem({ key: 'threeDMaterialCollapsed', collapsed })
      }>
      <MaterialPicker
        sizePreference={sizePreference}
        label="Color"
        showHexField
        address={address}
        materialColor={materialColor}
        materialEColorLig={materialEColorLig}
        materialEColorTop={materialEColorTop}
        materialEColorSha={materialEColorSha}
        materialIColor={materialIColor}
        materialIEmissiveColor={materialIEmissiveColor}
        materialKeepStylesInSync={materialKeepStylesInSync}
        materialType={materialType}
        setPropertyState={setPropertyState}
      />

      {isPTypeSelected ? (
        <>
          <PropertyInputSlider
            size={sizePreference}
            variant="filled"
            min={0}
            max={1}
            step={0.001}
            aria-label="Roughness"
            label="Roughness"
            value={materialRoughness}
            onInput={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                address,
                key: 'materialRoughness',
                value
              })
            }}
            onMouseDown={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                address,
                key: 'materialRoughness',
                value,
                commit: EngineCommitChange.BEGIN_COMMIT
              })
            }}
            onMouseUp={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                address,
                key: 'materialRoughness',
                value,
                commit: EngineCommitChange.END_COMMIT
              })
            }}
            editable
            hideStepper
          />
          <PropertyInputSlider
            size={sizePreference}
            variant="filled"
            min={0}
            max={1}
            step={0.001}
            aria-label="Metallic"
            label="Metallic"
            value={materialMetalness}
            onInput={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                address,
                key: 'materialMetalness',
                value
              })
            }}
            onMouseDown={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                address,
                key: 'materialMetalness',
                value,
                commit: EngineCommitChange.BEGIN_COMMIT
              })
            }}
            onMouseUp={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                address,
                key: 'materialMetalness',
                value,
                commit: EngineCommitChange.END_COMMIT
              })
            }}
            editable
            hideStepper
          />
          <div className="flex align-center gap-xs">
            <Checkbox
              id="primitive-material-reflective"
              size={sizePreference}
              checked={materialReflective}
              change={e =>
                setPropertyState({
                  address,
                  key: 'materialReflective',
                  value: !materialReflective
                })
              }></Checkbox>
            <FieldLabel for="primitive-material-reflective">
              Reflective
            </FieldLabel>
          </div>
        </>
      ) : isETypeSelected || isPixelTypeSelected ? (
        <>
          <PropertyInputSlider
            size={sizePreference}
            variant="filled"
            disabled // TODO needs to be fixed in engine
            min={0}
            max={1}
            step={0.01}
            aria-label="Highlight Size"
            label="Highlight Size"
            value={materialESpecularSize}
            onInput={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                address,
                key: 'materialESpecularSize',
                value
              })
            }}
            onMouseDown={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                address,
                key: 'materialESpecularSize',
                value,
                commit: EngineCommitChange.BEGIN_COMMIT
              })
            }}
            onMouseUp={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                address,
                key: 'materialESpecularSize',
                value,
                commit: EngineCommitChange.END_COMMIT
              })
            }}
            editable
            hideStepper
          />

          <PropertyInputSlider
            size={sizePreference}
            variant="filled"
            min={0}
            max={1}
            step={0.01}
            aria-label="Highlight Intensity"
            label="Highlight Intensity"
            value={materialESpecularIntensity}
            onInput={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                address,
                key: 'materialESpecularIntensity',
                value
              })
            }}
            onMouseDown={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                address,
                key: 'materialESpecularIntensity',
                value,
                commit: EngineCommitChange.BEGIN_COMMIT
              })
            }}
            onMouseUp={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                address,
                key: 'materialESpecularIntensity',
                value,
                commit: EngineCommitChange.END_COMMIT
              })
            }}
            editable
            hideStepper
          />
        </>
      ) : (
        <>
          <PropertyInputSlider
            size={sizePreference}
            variant="filled"
            min={0}
            max={1}
            step={0.001}
            aria-label="Stroke Size"
            label="Stroke Size"
            value={materialIStrokeSize}
            onInput={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                address,
                key: 'materialIStrokeSize',
                value
              })
            }}
            onMouseDown={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                address,
                key: 'materialIStrokeSize',
                value,
                commit: EngineCommitChange.BEGIN_COMMIT
              })
            }}
            onMouseUp={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                address,
                key: 'materialIStrokeSize',
                value,
                commit: EngineCommitChange.END_COMMIT
              })
            }}
            editable
            hideStepper
          />

          <PropertyInputSlider
            size={sizePreference}
            variant="filled"
            min={0}
            max={1}
            step={0.01}
            aria-label="Highlight Intensity"
            label="Highlight Intensity"
            value={materialIHighlightIntensity}
            onInput={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                address,
                key: 'materialIHighlightIntensity',
                value
              })
            }}
            onMouseDown={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                address,
                key: 'materialIHighlightIntensity',
                value,
                commit: EngineCommitChange.BEGIN_COMMIT
              })
            }}
            onMouseUp={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                address,
                key: 'materialIHighlightIntensity',
                value,
                commit: EngineCommitChange.END_COMMIT
              })
            }}
            editable
            hideStepper
          />

          <PropertyInputSlider
            size={sizePreference}
            variant="filled"
            min={0}
            max={1}
            step={0.01}
            aria-label="Paint Stroke Intensity"
            label="Paint Stroke Intensity"
            value={materialIStrokeIntensity}
            onInput={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                address,
                key: 'materialIStrokeIntensity',
                value
              })
            }}
            onMouseDown={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                address,
                key: 'materialIStrokeIntensity',
                value,
                commit: EngineCommitChange.BEGIN_COMMIT
              })
            }}
            onMouseUp={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                address,
                key: 'materialIStrokeIntensity',
                value,
                commit: EngineCommitChange.END_COMMIT
              })
            }}
            editable
            hideStepper
          />

          <PropertyInputSlider
            size={sizePreference}
            variant="filled"
            min={0}
            max={1}
            step={0.01}
            aria-label="Color Variation"
            label="Stroke Color Variation"
            value={materialIColorVarIntensity}
            onInput={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                address,
                key: 'materialIColorVarIntensity',
                value
              })
            }}
            onMouseDown={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                address,
                key: 'materialIColorVarIntensity',
                value,
                commit: EngineCommitChange.BEGIN_COMMIT
              })
            }}
            onMouseUp={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                address,
                key: 'materialIColorVarIntensity',
                value,
                commit: EngineCommitChange.END_COMMIT
              })
            }}
            editable
            hideStepper
          />

          <PropertyInputSlider
            size={sizePreference}
            variant="filled"
            min={0}
            max={1}
            step={0.01}
            aria-label="Scale X"
            label="Scale X"
            value={materialIScaleX}
            onInput={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                address,
                key: 'materialIScaleX',
                value
              })
            }}
            onMouseDown={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                address,
                key: 'materialIScaleX',
                value,
                commit: EngineCommitChange.BEGIN_COMMIT
              })
            }}
            onMouseUp={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                address,
                key: 'materialIScaleX',
                value,
                commit: EngineCommitChange.END_COMMIT
              })
            }}
            editable
            hideStepper
          />

          <PropertyInputSlider
            size={sizePreference}
            variant="filled"
            min={0}
            max={1}
            step={0.01}
            aria-label="Scale Y"
            label="Scale Y"
            value={materialIScaleY}
            onInput={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                address,
                key: 'materialIScaleY',
                value
              })
            }}
            onMouseDown={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                address,
                key: 'materialIScaleY',
                value,
                commit: EngineCommitChange.BEGIN_COMMIT
              })
            }}
            onMouseUp={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                address,
                key: 'materialIScaleY',
                value,
                commit: EngineCommitChange.END_COMMIT
              })
            }}
            editable
            hideStepper
          />

          <PropertyInputSlider
            size={sizePreference}
            variant="filled"
            min={0}
            max={1}
            step={0.01}
            aria-label="Angle"
            label="Angle"
            value={materialIAngle}
            onInput={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                address,
                key: 'materialIAngle',
                value
              })
            }}
            onMouseDown={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                address,
                key: 'materialIAngle',
                value,
                commit: EngineCommitChange.BEGIN_COMMIT
              })
            }}
            onMouseUp={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                address,
                key: 'materialIAngle',
                value,
                commit: EngineCommitChange.END_COMMIT
              })
            }}
            editable
            hideStepper
          />
        </>
      )}
    </PanelAccordion>
  )
}

export default memo(Material)
