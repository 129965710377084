import { useEffect } from 'react'
import { useGetProjectByUuidQuery } from '@store/graphql/__generated__/schema'
import { useProjectActions } from '@hooks/useProject'
import { useSceneActions } from '@hooks/useScene'
import { useAuthState } from '@hooks/useAuth'
import { useRouter } from 'next/router'

export const useInitializeProject = (uuid?: string) => {
  const { data } = useGetProjectByUuidQuery({
    variables: { uuid: uuid || '' },
    skip: !uuid
  })
  const router = useRouter()

  const project = data?.projectByUuid

  const localUser = useAuthState('localUser')

  const {
    setProjectUuid,
    setIsFeatured,
    setName,
    setOwnerUserUuid,
    setIsPublic,
    loadProject
  } = useProjectActions()

  const { setEngineState } = useSceneActions()

  useEffect(() => {
    if (!uuid || !project || !localUser) return

    const isProjectOwner = localUser.uuid === project.ownerUserUuid
    // Private projects can only be accessible to users who owns the project
    if (!project.public && !isProjectOwner) {
      router.push('/discover')
      return
    }

    setProjectUuid(uuid)
    setIsFeatured(project.featured!)
    setIsPublic(project.public!)
    setName(project.name!)
    setOwnerUserUuid(project.ownerUserUuid || null)
    setEngineState('INITIALIZE')
    loadProject({ requireAuth: true })

    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [uuid, project, localUser])
}
