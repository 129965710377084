import React, { FC, useEffect, useState } from 'react'
import { ActionMenu, Link, MenuItem, ProgressCircle, Toast } from 'ui'
import LibraryThumbnailGrid from './LibraryThumbnailGrid'
import LibraryThumbnail from './LibraryThumbnail'
import InlineAlert from '@components/inlineAlert/InlineAlert'
import { useUserLibrary } from '@hooks/useUserLibrary'
import { UserLibraryMaterial } from '@store/slices/userLibrarySlice'
import { useSceneState } from '@hooks/useScene'
import { EngineSelectedSceneNode } from '@services/engine/types'

interface Props {
  onViewAll?: () => void
  totalItems?: number
}

const UserLibraryMaterialItems: FC<Props> = ({ onViewAll, totalItems }) => {
  const [showToast, setShowToast] = useState(false)

  const selectedSceneNode = useSceneState('selectedSceneNode')

  const {
    applyUserLibraryMaterialToSelection,
    archiveUserLibraryAsset,
    userLibraryMaterials,
    fetchUserLibraryMaterials
  } = useUserLibrary()

  const showViewAllLink =
    onViewAll &&
    totalItems &&
    userLibraryMaterials?.length &&
    userLibraryMaterials.length > totalItems

  useEffect(() => {
    fetchUserLibraryMaterials()
  }, [])

  useEffect(() => {
    let timeout: NodeJS.Timeout

    if (showToast) {
      timeout = setTimeout(() => {
        setShowToast(false)
      }, 3000)
    }

    return () => {
      timeout && clearTimeout(timeout)
    }
  }, [showToast])

  function applyMaterial(material: UserLibraryMaterial) {
    if (
      selectedSceneNode === EngineSelectedSceneNode.PROJECT ||
      selectedSceneNode === EngineSelectedSceneNode.NONE
    ) {
      setShowToast(true)
    } else {
      applyUserLibraryMaterialToSelection(material.document)
      setShowToast(false)
    }
  }

  function onMenuClick(
    { target: { value } }: any,
    material: UserLibraryMaterial
  ) {
    switch (value) {
      case 'user-library-menu-item-apply-material':
        return applyMaterial(material)
      case 'user-library-menu-item-delete-material':
        return archiveUserLibraryAsset({
          type: 'material',
          assetUuid: material.uuid!
        })
      default:
        return
    }
  }

  if (userLibraryMaterials === null) {
    return <ProgressCircle indeterminate />
  }
  if (!userLibraryMaterials.length) {
    return (
      <InlineAlert>You have no materials saved to your library.</InlineAlert>
    )
  }
  return (
    <>
      <LibraryThumbnailGrid>
        {userLibraryMaterials
          .filter((_, index) => (totalItems ? index < totalItems : true))
          .map(m => (
            <LibraryThumbnail
              key={m.uuid}
              actionMenu={
                <ActionMenu size="s" quiet onchange={e => onMenuClick(e, m)}>
                  <MenuItem value="user-library-menu-item-apply-material">
                    Apply to selection
                  </MenuItem>
                  <MenuItem value="user-library-menu-item-delete-material">
                    Delete
                  </MenuItem>
                </ActionMenu>
              }
              label={m.name || ''}>
              <div
                style={{
                  borderRadius: 8,
                  height: 40,
                  width: 40,
                  backgroundColor: m.document.materialColor
                }}
              />
            </LibraryThumbnail>
          ))}
      </LibraryThumbnailGrid>
      {showViewAllLink && (
        <Link
          style={{ alignSelf: 'flex-end' }}
          variant="secondary"
          onClick={onViewAll}>
          View all
        </Link>
      )}

      <Toast
        style={{
          position: 'fixed',
          bottom: '100px',
          left: 'calc(50% - 320px / 2)',
          transform: 'translateX(-50%)'
        }}
        open={showToast}
        close={() => {
          setShowToast(false)
        }}
        variant="info">
        Select object to apply material
      </Toast>
    </>
  )
}

export default UserLibraryMaterialItems
