import React, { FC } from 'react'
import Rail from '@components/rail/Rail'
import styles from '@styles/components/LeftRail.module.scss'
import cn from 'classnames'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import MaterialPicker from '@components/propertiesPanel/MaterialPicker'
import { Divider, OverlayTrigger, Tooltip } from 'ui'
import Toolbar from '@components/propertiesPanel/Toolbar'
import CursorOverlay from '@components/cursorOverlay/CursorOverlay'
import { ClassNameProps } from 'types/reactProps'
import { EngineSelectedSceneNode } from '@services/engine/types'
import { useProjectState } from '@hooks/useProject'

const LeftRail: FC<ClassNameProps> = ({ className }) => {
  const engineState = useSceneState('engineState')
  const address = useSceneState('address')
  const materialColor = useSceneState('materialColor')
  const materialEColorLig = useSceneState('materialEColorLig')
  const materialEColorTop = useSceneState('materialEColorTop')
  const materialEColorSha = useSceneState('materialEColorSha')
  const materialIColor = useSceneState('materialIColor')
  const materialIEmissiveColor = useSceneState('materialIEmissiveColor')
  const materialKeepStylesInSync = useSceneState('materialKeepStylesInSync')
  const materialType = useSceneState('materialType')
  const selectedSceneNode = useSceneState('selectedSceneNode')

  const sizePreference = useProjectState('sizePreference')
  const materialPickerSize = sizePreference === 'l' ? 'm' : 's'

  const { setPropertyState: _setPropertyState } = useSceneActions()

  const showMaterialPicker = selectedSceneNode === EngineSelectedSceneNode.SHAPE

  const setPropertyState = React.useCallback(_setPropertyState, [])
  return (
    <Rail className={cn(styles['container'], className)}>
      {engineState !== 'INITIALIZE' && <Toolbar />}

      {showMaterialPicker && (
        <>
          <Divider size="m" style={{ width: 20 }} />
          <OverlayTrigger placement="right">
            <div slot="trigger">
              <div className={styles['material-picker-container']}>
                <MaterialPicker
                  sizePreference={materialPickerSize}
                  address={address}
                  className={styles['color-picker']}
                  materialColor={materialColor}
                  materialEColorLig={materialEColorLig}
                  materialEColorTop={materialEColorTop}
                  materialEColorSha={materialEColorSha}
                  materialIColor={materialIColor}
                  materialIEmissiveColor={materialIEmissiveColor}
                  materialKeepStylesInSync={materialKeepStylesInSync}
                  materialType={materialType}
                  setPropertyState={setPropertyState}
                />
              </div>
            </div>
            <Tooltip slot="hover-content">Material</Tooltip>
          </OverlayTrigger>
        </>
      )}

      <div className={styles['bottom-content']}></div>

      <CursorOverlay />
    </Rail>
  )
}

export default LeftRail
