import React from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import AddObject from './AddObject'
import StylesPicker from './StylesPicker'
import FireflyShowGeneratePromptButton from '@components/fireflyPanel/FireflyShowGeneratePromptButton'

const SceneContextBar = () => {
  const flags = useFlags()

  return (
    <>
      <AddObject />
      <StylesPicker />
      {flags['tf-ui-firefly-generate-prompt-btn-no-object-selected'] && (
        <FireflyShowGeneratePromptButton />
      )}
    </>
  )
}

export default SceneContextBar
