import React, { FC } from 'react'
import { PanelKeys, StudioPanel } from '@store/slices/projectSlice'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { ActionButton, Icon, OverlayTrigger, Tooltip } from 'ui'
import { SpIconPeopleGroup, SpIconPlayCircle } from 'workflow-icons'
import ObjectPropertiesIcon from '/public/s2_icon_properties.svg'
import ViewsIcon from '/public/s2_icon_viewport.svg'
import MeetIcon from '/public/s2_icon_movie_camera.svg'
import ChatIcon from '/public/s2_icon_comment.svg'
import FireflyIcon from '/public/s2_icon_ai_generate.svg'
import CommunityIcon from '/public/s2_icon_community.svg'
import AssetIcon from '/public/s2_icon_asset.svg'
import LayersIcon from '/public/s2_icon_layers.svg'
import StyleIcon from '/public/s2_icon_filters.svg'
import {
  useMediumMinimumSizePreference,
  useProjectActions,
  useProjectState
} from '@hooks/useProject'
import Rail from '@components/rail/Rail'
import CursorOverlay from '@components/cursorOverlay/CursorOverlay'
import { useIsEmptyScene } from '@hooks/useScene'
import { useFirefly } from '@hooks/useFirefly'
import { ClassNameProps } from 'types/reactProps'

const PanelTriggers: {
  value: PanelKeys
  DefaultIcon?: any
  SpIcon?: typeof SpIconPeopleGroup
  tooltip: string
  featureFlagKey?: string
}[] = [
  {
    value: StudioPanel.Layers,
    tooltip: 'Layers',
    DefaultIcon: LayersIcon,
    featureFlagKey: 'base-pf-ui-layers-panel'
  },
  {
    value: StudioPanel.Properties,
    DefaultIcon: ObjectPropertiesIcon,
    tooltip: 'Properties'
  },
  {
    value: StudioPanel.Library,
    tooltip: 'Library',
    DefaultIcon: AssetIcon,
    featureFlagKey: 'base-pf-ui-user-library'
  },
  {
    value: StudioPanel.Style,
    tooltip: 'Styles',
    DefaultIcon: StyleIcon,
    featureFlagKey: 'base-pf-ui-styles-panel'
  },
  {
    value: StudioPanel.Firefly,
    tooltip: 'Adobe Firefly',
    DefaultIcon: FireflyIcon,
    featureFlagKey: 'base-pf-ui-firefly'
  },
  {
    value: StudioPanel.Views,
    tooltip: 'Views',
    DefaultIcon: ViewsIcon,
    featureFlagKey: 'base-pf-ui-views'
  },
  {
    value: StudioPanel.Meet,
    tooltip: 'Meet',
    DefaultIcon: MeetIcon,
    featureFlagKey: 'base-pf-ui-chime'
  },
  {
    value: StudioPanel.Chat,
    tooltip: 'Chat',
    DefaultIcon: ChatIcon,
    featureFlagKey: 'base-pf-ui-chat'
  },
  {
    value: StudioPanel.Player,
    tooltip: 'Player',
    SpIcon: SpIconPlayCircle,
    featureFlagKey: 'base-pf-ui-player'
  },
  {
    value: StudioPanel.Community,
    tooltip: 'Community',
    DefaultIcon: CommunityIcon,
    featureFlagKey: 'base-pf-ui-community'
  }
]

const RightRail: FC<ClassNameProps> = ({ className }) => {
  const flags = useFlags()

  const isEmptyScene = useIsEmptyScene()

  const { setShowEmptySceneDialog } = useFirefly()

  const openedPanel = useProjectState('openedPanel')
  const size = useMediumMinimumSizePreference()
  const { setOpenedPanel, setShowFireflyPopover } = useProjectActions()

  const panelTriggers = PanelTriggers.filter(trigger => {
    if (trigger.featureFlagKey) {
      return flags[trigger.featureFlagKey]
    }

    return true
  })

  function togglePanelVisibility(panel: PanelKeys, visibility: boolean) {
    if (panel === StudioPanel.Firefly) {
      if (visibility && isEmptyScene) {
        setShowEmptySceneDialog(true)
        return
      }
      if (visibility) {
        setShowFireflyPopover(true)
      }
    }

    setOpenedPanel(openedPanel === panel ? null : panel)
  }

  return (
    <Rail className={className}>
      {panelTriggers.map(({ DefaultIcon, SpIcon, tooltip, value }) => {
        const isSelected = openedPanel === value

        return (
          <OverlayTrigger key={value} placement="left">
            <ActionButton
              size={size}
              slot="trigger"
              quiet
              value={value}
              selected={isSelected}
              onClick={() => togglePanelVisibility(value, !isSelected)}>
              {SpIcon ? (
                <SpIcon slot="icon" />
              ) : (
                <Icon slot="icon">
                  <DefaultIcon />
                </Icon>
              )}
            </ActionButton>
            <Tooltip slot="hover-content">{tooltip}</Tooltip>
          </OverlayTrigger>
        )
      })}
      <CursorOverlay />
    </Rail>
  )
}

export default RightRail
