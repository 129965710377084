import React, { FC, memo, useState } from 'react'
import { ColorPicker, ColorSync } from 'ui'
import { SceneStateProperties } from '@hooks/useScene'
import { EngineCommitChange, MaterialType } from '@services/engine/types'
import { TinyColor } from '@ctrl/tinycolor'
import ColorPickerWithTextfield from '@components/colorPickerWithTextfield/ColorPickerWithTextfield'
import { ProjectState } from '@store/slices/projectSlice'

type Props = Pick<
  SceneStateProperties,
  | 'address'
  | 'materialColor'
  | 'materialEColorLig'
  | 'materialEColorTop'
  | 'materialEColorSha'
  | 'materialIColor'
  | 'materialIEmissiveColor'
  | 'materialKeepStylesInSync'
  | 'materialType'
  | 'setPropertyState'
> & {
  sizePreference?: ProjectState['sizePreference']
  className?: string
  label?: string
  showHexField?: boolean
}

type SwatchId =
  | 'materialEColorLig'
  | 'materialEColorTop'
  | 'materialEColorSha'
  | 'materialIColor'
  | 'materialIEmissiveColor'

type Swatch = {
  id: SwatchId
  color: string
}

const MaterialPicker: FC<Props> = ({
  address,
  className,
  label,
  materialColor,
  materialEColorLig,
  materialEColorTop,
  materialEColorSha,
  materialIColor,
  materialIEmissiveColor,
  materialKeepStylesInSync,
  materialType,
  setPropertyState,
  showHexField,
  sizePreference
}) => {
  const isPTypeSelected = materialType === MaterialType.P
  const isETypeSelected = materialType === MaterialType.E
  const isPixelTypeSelected = materialType === MaterialType.PIXEL

  const swatches: Swatch[] =
    isETypeSelected || isPixelTypeSelected
      ? [
          {
            id: 'materialEColorTop',
            color: materialEColorTop
          },
          {
            id: 'materialEColorLig',
            color: materialEColorLig
          },
          {
            id: 'materialEColorSha',
            color: materialEColorSha
          }
        ]
      : [
          {
            id: 'materialIColor',
            color: materialIColor
          },
          {
            id: 'materialIEmissiveColor',
            color: materialIEmissiveColor
          }
        ]

  const [selectedSwatch, setSelectedSwatch] = useState<SwatchId>(
    isETypeSelected || isPixelTypeSelected
      ? 'materialEColorTop'
      : 'materialIColor'
  )

  const selectedSwatchColor =
    materialKeepStylesInSync && (isETypeSelected || isPixelTypeSelected)
      ? swatches[1].color
      : materialKeepStylesInSync
      ? swatches[0].color
      : swatches.find(({ id }) => id === selectedSwatch)?.color || '#ffffff'

  function handleSwatchSelected(event: CustomEvent<{ id: SwatchId }>) {
    setSelectedSwatch(event.detail.id as SwatchId)
    commitSwatch(event.detail.id)
  }

  function handleMultiColorPickerUpdate(
    event: {
      target?: { color?: string }
    },
    commit?: EngineCommitChange
  ) {
    const value = event.target?.color
    if (!value) return
    if (isETypeSelected || isPixelTypeSelected) {
      if (materialKeepStylesInSync) {
        const colorTop = new TinyColor(value).lighten().toHex()
        const colorSha = new TinyColor(value).darken(20).toHex()

        setPropertyState({
          key: 'materialEColorTop',
          value: '#' + colorTop,
          commit
        })

        setPropertyState({
          key: 'materialEColorLig',
          value,
          commit
        })

        setPropertyState({
          key: 'materialEColorSha',
          value: '#' + colorSha,
          commit
        })
      } else {
        setPropertyState({
          key: selectedSwatch,
          value,
          commit
        })
      }
    } else {
      if (materialKeepStylesInSync) {
        setPropertyState({
          address,
          key: 'materialIColor',
          value,
          commit
        })

        setPropertyState({
          address,
          key: 'materialIEmissiveColor',
          value,
          commit
        })
      } else {
        setPropertyState({
          address,
          key: selectedSwatch,
          value,
          commit
        })
      }
    }
  }

  function commitSwatch(id: string) {
    swatches.forEach(swatch => {
      if (swatch.id === id) {
        setPropertyState({
          address,
          key: swatch.id as any,
          value: swatch.color
        })
      }
    })
  }

  const Picker = showHexField ? ColorPickerWithTextfield : ColorPicker

  return (
    <>
      {isPTypeSelected ? (
        <>
          <Picker
            size={sizePreference}
            label={label}
            className={className}
            color={materialColor}
            forceUpdateColorAreaHandlePosition={materialColor}
            showSwatches={false}
            onMouseDown={e =>
              setPropertyState({
                key: 'materialColor',
                value: (e.target as any).color,
                commit: EngineCommitChange.BEGIN_COMMIT
              })
            }
            onInput={e =>
              setPropertyState({
                key: 'materialColor',
                value: (e.target as any).color
              })
            }
            onChange={e =>
              setPropertyState({
                key: 'materialColor',
                value: (e.target as any).color,
                commit: EngineCommitChange.END_COMMIT
              })
            }
          />
        </>
      ) : isETypeSelected || isPixelTypeSelected ? (
        <Picker
          size={sizePreference}
          label={label}
          className={className}
          swatches={swatches}
          showSwatches={materialKeepStylesInSync}
          showHexField={materialKeepStylesInSync}
          color={selectedSwatchColor}
          forceUpdateColorAreaHandlePosition={selectedSwatchColor}
          onMouseDown={e =>
            handleMultiColorPickerUpdate(e, EngineCommitChange.BEGIN_COMMIT)
          }
          onInput={handleMultiColorPickerUpdate}
          onChange={e =>
            handleMultiColorPickerUpdate(e, EngineCommitChange.END_COMMIT)
          }>
          <ColorSync
            style={{ marginTop: 10 }}
            slot="inputs"
            swatches={swatches}
            checked={materialKeepStylesInSync}
            selectedSwatch={selectedSwatch}
            onSwatchClick={handleSwatchSelected}
            onSwitchClick={() =>
              setPropertyState({
                address,
                key: 'materialKeepStylesInSync',
                value: !materialKeepStylesInSync
              })
            }
          />
        </Picker>
      ) : (
        <Picker
          size={sizePreference}
          label={label}
          className={className}
          swatches={swatches}
          showSwatches={materialKeepStylesInSync}
          showHexField={materialKeepStylesInSync}
          color={selectedSwatchColor}
          onMouseDown={e =>
            handleMultiColorPickerUpdate(e, EngineCommitChange.BEGIN_COMMIT)
          }
          onInput={e => handleMultiColorPickerUpdate(e, EngineCommitChange)}
          onChange={e =>
            handleMultiColorPickerUpdate(e, EngineCommitChange.END_COMMIT)
          }>
          <ColorSync
            style={{ marginTop: 10 }}
            slot="inputs"
            swatches={swatches}
            checked={materialKeepStylesInSync}
            selectedSwatch={selectedSwatch}
            onSwatchClick={handleSwatchSelected}
            onSwitchClick={() =>
              setPropertyState({
                address,
                key: 'materialKeepStylesInSync',
                value: !materialKeepStylesInSync
              })
            }
          />
        </Picker>
      )}
    </>
  )
}

export default memo(MaterialPicker)
